/*! Accordion 1.1.1
 * ==========================
 * @desc - Simple accordion
 * @requires - jQuery 1.7+ | The associated css (accordion.css)
 * @notes - 
 */  
(function ($) {
    $.fn.extend({
        accordion: function () {

            function setActive(self, $el) {
                var hash = $el.attr('href');
                var elm = $(hash);

                if (elm.length > 0) {
                    $(self).find(".accordion-item-open").removeClass("accordion-item-open");                  
                    elm.addClass('accordion-item-open');
                }
            }

            function setActiveViaHash(self, hash) {
                var elm = $(hash);

                if (elm.length > 0) {
                    $(self).find(".accordion-item-open").removeClass("accordion-item-open");                  
                    elm.addClass('accordion-item-open');
                }
            }


            return this.each(function () {
                var self = this;
                
                //location hash
                if (location.hash) {
                    setActiveViaHash(self, location.hash);
                }

                //triggers
                $(this).find("a.accordion-trigger[href^='#']").on("click", function(e) {
                    // console.log('loggy log log');
                    e.preventDefault();
                    if(!$(this).parent().hasClass("accordion-item-open")){
                        setActive(self, $(this));
                        return false;    
                    } else{
                        $(this).parent().removeClass("accordion-item-open");
                    }
                });

                //default accordion-active - no longer needed as done via css
                // var firstActive = ($(this).find('li.accordion-active a').length > 0) ? $(this).find('li.accordion-active a') : $(this).find('li:first a');
                // firstActive.click();



                // if ("onhashchange" in window) {
                //     window.onhashchange = setActiveViaHash(self, location.hash);
                // }

            });
        }
    });
})(jQuery);


//by default, initialise on elements with the class of .tabbed...
$(document).ready(function () {
    $('.accordion').accordion();
    console.log("ACCORDION GO");
});
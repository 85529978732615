/*
	Define page specific scripts here which will be loaded by _domreadyutil.js based on the ID and class of the body tag 
	of any page
 */

// The utility converts dashed classnames to camelCase as we can't use dashes in our variables here so resourcesPage = resources-page

PAGESCRIPT = {
  	common : {
    	init     : function(){
			sniffbrowser();

			// Menu and login/searchBox
			$(window).on('load resize', menuHover);

		    $("#mobile-nav-close").click(function() {
		        $("body").removeClass("is-offcanvas-open");
		        $(".submenu-opened").removeClass("submenu-opened");
		        $(".submenu").removeAttr( 'style' );
		        $(".hidden-box").removeClass("hidden-box-opened").removeAttr( 'style' );
		        $(".main-nav-search, .main-nav-login, .hidden-box-sibling").removeClass("hidden-box-opened");
		    });

			//console.error($("#mobile-menu-button"));
		    $("#mobile-menu-button").on("click", toggleMobileMenu);
		    $(".submenu-sibling").click(openSubmenu);
		    $(window).on('resize', closeMobileMenu);

		    $('.hidden-box-sibling').click(function(e) {
		        e.preventDefault();
		        var $boxSibling = $(".hidden-box-sibling");
		        $boxSibling.not(this).removeClass("hidden-box-opened");
		        $(this).addClass("hidden-box-opened");
		        $(".main-nav-search, .main-nav-login").removeClass("hidden-box-opened");

		        if ($(window).width() >= 991) {
		            $boxSibling.not(this).siblings('.hidden-box').removeClass("hidden-box-opened").hide("fast");
		            $(this).siblings(".hidden-box").addClass("hidden-box-opened").show('fast');
		        }

		        if ($(window).width() < 992) {
		            $boxSibling.not(this).siblings('.hidden-box').removeClass("hidden-box-opened");
		            $(this).siblings(".hidden-box").addClass("hidden-box-opened");
		            $(this).parent(".main-nav-search, .main-nav-login").addClass("hidden-box-opened");
		        }
		    });

		    $(document).click(function(e) {

		        if ($(e.target).closest(".hidden-box, .hidden-box-sibling").length || e.target.nodeName === undefined) {
		            console.log($(e.target).is("a"),$(e.target).hasClass("form-submit"));
		            if(!$(e.target).hasClass("form-submit") && !$(e.target).is("a")){
		                // console.log(e.target.nodeName);
		                e.preventDefault();
		                return false;
		            }
		            console.log("Called1b");
		        } else if ($(window).width() >= 991) {
		            $(".hidden-box").removeClass("hidden-box-opened").hide("fast");
		            $('.hidden-box-sibling').removeClass("hidden-box-opened");
		            // e.stopPropagation();
		        }
		    });
			// End menu code

			//Some non-common nastiness that's easier to leave here for now
			$(".search-result-block").click(function(){
			    $("a.search-result-title", this)[0].click();
			});

			$(".article-excerpt-container").click(function(){
			    $("a.read-more-link", this)[0].click();
			});

			$(".insurance-services-index-page .excerpt-wrap").click(function(){
			    $("a.read-more-link", this)[0].click();
			});

			$('.get-a-quote-page select.not-selected').change(function() {
			    $(this).children(":selected").parent('.not-selected').removeClass('not-selected');
			});
			// End


			/*-------------------------------------------------------*/
			/*- FORM VALIDATION                                     -*/
			/*-------------------------------------------------------*/
			//Marl the form fiels as answered on change so that we can show validationj styles apprpriately and not before they have been used.
			//https://css-tricks.com/form-validation-ux-html-css/ - Technique used in thie article (:not(:placeholder-shown) is not available in FF and IE yet)

			$('body').on('change',"input, select, textarea",function(){
			    var wrapper = $(this).closest("div");

			    //if($(this).val().length > 0) {
			    if($(this).val() !== null && $(this).val().length > 0) {//prior to jquery 3.0 $(this).val() will return null for select multiples with zero items selected so we have to test for both
			        wrapper.addClass("is-answered");
			    } else{
			        wrapper.removeClass("is-answered");
			    }
			});

            /* init cookiebar */
            (function(){
                var checkbox = document.getElementById('hideCookieNotice'),
                    cookieVal = docCookies.getItem("hideCookieNotice");
                //NB: chrome wont allow cookies during local testing (by FF will)
                if(checkbox){
                    checkbox.checked = (cookieVal);
                    checkbox.addEventListener('change', function(event){
                        if (event.target.checked) {
                            //console.log('checked');
                            docCookies.setItem("hideCookieNotice","true",365);
                        } else {
                            //console.log('not checked');
                            docCookies.removeItem("hideCookieNotice");
                        }
                    });
                }
            })();

    	},
    	finalize : function() {

    	},
        ajax : function() {

            setupChosen();

        	$(".console-pane-action").off().on('click',function(e){
        		var url = $(this).attr("href"),
					thisAction = $(this);

        		e.preventDefault();

        		//console.log("CONSOLE AJAX",url);

                $.ajax(window.contextPath + url)
                    .done(function() {
                    	console.log("success!");
                        thisAction.removeClass("btn_a").text("Quotes Sent").removeAttr("href").off('click');
                    }
				);
			});
        }
  	},
  	homepage : {
  		init 	: function() {
  			if($(".info-link").length>0) {
  				$("body").on('click mouseover mouseleave',".info-link",function(e){
  					e.preventDefault();
  					e.stopPropagation();

  					if($(this).hasClass("info-active")) {
						$(this).removeClass("info-active");
  					} else{
  						$(".info-active").removeClass("info-active");
  						$(this).addClass("info-active");
  					}
  				});
  			}
  			if($("#scrollDown").length>0) {
  				$("#scrollDown").on("click",function(e){
  					e.preventDefault();
  					var scrollToPos = $("#main").offset().top;
  					$('body,html').animate({
						scrollTop: scrollToPos
					}, 800);
					return false;
  				});
  			}
  		}
  	},
  	  coverPage : {
  		init 	: function() {
  			if($(".info-link").length>0) {
  				$("body").on('click mouseover mouseleave',".info-link",function(e){
  					e.preventDefault();
  					e.stopPropagation();
  					if($(this).hasClass("info-active")) {
						$(this).removeClass("info-active");
  					} else{
  						$(".info-active").removeClass("info-active");
  						$(this).addClass("info-active");
  					}
  				});
  			}
  		}
  	},
  	contactPage : {
    	init     : function() {
			/****************** Isotope Script ***************************/

			            // Copy categories to item classes
			$('.filtered-item').each(function(i) {
			    var $this = $(this);
			    var rThis = $this.attr('data-services');
			    //stunn addition to remove ',' so isotope will filter all categories listed
			    //make sure data is being added to 'data-services' on item to filter
			    if (rThis){
			        $this.attr('data-services',rThis.replace(/,/g,' service') );
			    }
			    $this.addClass( $this.attr('data-services') );
			});

			var $grid = $('.filtered-items-parent').isotope({
			  itemSelector: '.filtered-item',
			  layoutMode: 'fitRows'
			});

			$('#filter').change(function() {
			  var filterValue = $(this).children(":selected").attr('data-filter');
			  $grid.isotope({ filter: filterValue });
			});

            $(window).on("load", function (e) {

				if($(".map-link").length>0) {
					var defaultFilter = $(".map-link").first().attr("href").replace("#",'');
				}

		        $('.filtered-items-parent').isotope({
		          itemSelector: '.filtered-item',
		          layoutMode: 'fitRows',
		          filter: "." + defaultFilter
		        });
			    // if($(".longon-office").length){
			    // } else {
			    //     $('.filtered-items-parent').isotope({
			    //       itemSelector: '.filtered-item',
			    //       layoutMode: 'fitRows'
			    //     });
			    // }
			});

			/****************** Office tabs ***************************/

		    // $('.map-link-1').click(function() {
		    //   google.maps.event.trigger(map1, 'resize');
		    //   $grid.isotope({ filter: '.london-office' });
		    // });
		    // $('.map-link-2').click(function() {
		    //   google.maps.event.trigger(map2, 'resize');
		    //   $grid.isotope({ filter: '.bristol-office' });
		    // });

		    $('.map-link').click(function() {
		      var filter = $(this).attr("href").replace("#",'');
		      var mapID = $("#" + filter).find(".map").data("mapindex");
		      google.maps.event.trigger(window.map[mapID], 'resize');
		      $grid.isotope({ filter: '.' + filter });
		    });

		    // $('.map-link-3').click(function() {
		    //   google.maps.event.trigger(map3, 'resize');
		    //   $grid.isotope({ filter: '.edinburgh-office' });
		    // });
		    // $('.map-link-4').click(function() {
		    //   google.maps.event.trigger(map4, 'resize');
		    //   $grid.isotope({ filter: '.glasgow-office-1' });
		    // });

    	}
  	},
  	resourcesPage : {
  		init	: function() {
  			// Resource Page Filters
  			console.log("RESOURCES");
		    $('.filter-field input').on('change',function(e){
		        console.log("clicked")
		        $(this).closest('form').submit();
		    });

		    $('.ajax-form').submit(function(e){
		        e.preventDefault();

		        $('body').addClass("ajaxLoading");

		        var url     = $(this).attr('action'),
		            data    = $(this).serialize();

		        $('.search-results').load(url + data + " #ajax-results", function(responseText, statusText, xhr){
		            $('body').removeClass("ajaxLoading").addClass('ajaxLoaded');
		            var resultsCount = $('.results-count').text();//attr('data-count')
		            if(resultsCount.indexOf("result") == -1){resultsCount = "No results";}
		            $('.filter-button').attr('data-count',resultsCount);
		            // console.log($('.filter-button').attr('data-count'));
		        });

		    });

		    $("body").on('click','.pagedResults a',function(e){
		        e.preventDefault();

		        $('body').addClass("ajaxLoading");

		        var href = $(this).attr("href");

		        $('.search-results').load(href + " #ajax-results", function(responseText, statusText, xhr){
		            $('body').removeClass("ajaxLoading").addClass('ajaxLoaded');
		            var resultsCount = $('.results-count').text();//.attr('data-count');
		            if(resultsCount.indexOf("result") == -1){resultsCount = "No results";}
		            $('.filter-button').attr('data-count',resultsCount);
		        });
		    });

		    $('.filter-button').on('click',function(e){
		        e.preventDefault();

		        var scrollTo = $('.search-results').offset().top;
		        $('body,html').animate({
		            scrollTop: scrollTo
		        }, 800);
		    });
		    // End resources filters

		    // Filter open/close/store preference
		    var filterState = localStorage.getItem("resourceFilters");
		    console.log(filterState);

		    if(filterState === "closed"){
		        $(".resources-page .page-header").removeClass("is-open");
		    }

		    $( ".resources-toggle" ).on("click",function(){
		        var topsection = $(".page-header");
		        // console.log("WORKING")
		        topsection.toggleClass("is-open");

		        if(topsection.hasClass("is-open")){
		            localStorage.setItem("resourceFilters", "open");
		        } else{
		            localStorage.setItem("resourceFilters", "closed");
		        }

		    });
		    // End of filter open/close/store
  		}
  	},
  	masterPolicy	: {
  		init	: function() {
			$(".wizard-action").on('loaded', function(e){

		        var step = $(this).attr("data-step");

		        //initialization after modal loads contents
		        $("#wizard").wizard({initial_step: step});

		    });
		    //non-modal version
		    $("#wizard").wizard();
  		}
  	},
  	ourStoryPage	: {
  		init 	: function() {
  			function minHeight() {
			    var sidebar = $('.our-story-page .sidebar');
			    var mainSection = $('.our-story-page .main-section-container');
			    if ($(window).width() < 992) {
			        var height = sidebar.outerHeight();
			        mainSection.css("min-height", height+100);
			    }
			}

			$(window).on('load resize', minHeight);
  		}
  	},
  	getAQuotePage 	: {
  		init 	: function() {
  			$( ".datepicker" ).datepicker({dateFormat: 'dd/mm/yy'});

  			/****************** Form inline validation ***************************/

			$(document).ready(function(){
			    $("input").on("change",function(e){
			        console.log("INPUT")
			        var thisVal = $(this).val();
			        $(this).attr("value",thisVal);
			        console.log($(this),thisVal);
			    });
			});
  		}
  	},
  	pfAdmin		: {
  		init 	: function() {
  			String.prototype.capitalizeFirstLetter = function() {
			    return this.charAt(0).toUpperCase() + this.slice(1);
			}

			function setupDocumentsUploader() {
			    if (!document.getElementById('preview-template')) {
			        return;
			    }

			    Dropzone.autoDiscover = false;

			    var uploadPath = window.contextPath != undefined
			        ? window.contextPath + "/documents/upload" : "/documents/upload";

			    var dz = new Dropzone('div#fileUpload', {
			        url: uploadPath,
			        createImageThumbnails: false,
			        paramName: "file",
			        maxFilesize: 25,
			        parallelUploads: 1000,
			        autoProcessQueue: false,
			        uploadMultiple: false,
			        previewTemplate: document.getElementById('preview-template').innerHTML
			    });

                dz.on("error", function(file, response) {
                    alert(response.error);
                });

			    dz.on("sending", function(file, xhr, formData) {


			        var previewElement = $(file.previewElement);
			        var documentTypeID = previewElement.find('.type-sel').val();

			        if (documentTypeID == '-' || !documentTypeID) {
			            dz.cancelUpload(file);
			            alert("Please select a valid document type");
			        } else {
			            // select other data..
			            var isManualInput = (!$('#form').val() ||$('#form').val() == 'manual' || $('#form').val() == '') ;

			            var formID =-1;
			            var insuranceTypeID;
			            var year;

			            if (isManualInput) {
			                formID = -1;
			                insuranceTypeID = parseInt($('#insuranceType').val(),10);
                            year = $('#year').val();
			            } else {
			                formID = parseInt($('#form').val(),10);
			                insuranceTypeID = parseInt($('#form :selected').data('insurancetypeid'),10);
			                year = $('#form :selected').data('year');
			            }


                        formID = (formID>0)?formID:-1;
                        insuranceTypeID = (insuranceTypeID>0)?insuranceTypeID:-1;

			            // console.log(formID);
			            // console.log(insuranceTypeID);
			            // console.log($('#form :input').data('userDefinedDate'));

                        var date=$('#date').val();

                        var emailNotificationFlag = ($('#userDefinedEmailNotificationFlag').is(':checked'));

                        // if(!$('#date').val() || $('#date').val() == '')
                        // {
                        //     date = null;
                        // } else {
                        //     date = $('#date').val();
                        // }

                        var sendNotificationTo = $('#sendNotificationTo').val();

			            var clientID = $('#client').val();

			            formData.append("documentTypeID", documentTypeID);
			            formData.append("year", year);
			            formData.append("insuranceTypeID", insuranceTypeID);
			            formData.append("formID", formID);
			            formData.append("clientID", clientID);
			            formData.append("date",date);
			            formData.append("userDefinedEmailNotificationFlag",emailNotificationFlag);
			            formData.append("sendNotificationTo",sendNotificationTo);
			        }
			    });


			    $('body').on("click","#upload",function(e) {
			    	console.log("click")
			        e.preventDefault();
			        dz.processQueue();
			    });
			}

			(function(){
			    var customAlert = {
			        alertDialog : $("<div id='alertDialog'><div id='dialog-box'><div id='dialog-message'>message</div><div id='dialog-button-row'><a href='#'>Okay</a></div></div></div>"),
			        styles: "#alertDialog {display:none;position:fixed;top:0;right:0;bottom:0;left:0;background:#222222;background-color:rgba(0,0,0,.75);text-align:center;z-index:99999999;}"+
			        "#alertDialog #dialog-box {background-color:#ffffff;width:500px;max-width:80%;margin:auto;display:inline-block;vertical-align:middle;}"+
			        "#alertDialog:before {content:'';display:inline-block;height:100%;vertical-align:middle;margin-right:-0.25em;}"+
			        "#alertDialog #dialog-message {margin:0;padding:30px;text-align:center;}"+
			        "#alertDialog #dialog-button-row {background:none repeat scroll 0 0 rgba(100, 100, 100, 0.1);}"+
			        "#alertDialog #dialog-button-row a {background:rgb(192, 11, 23);color:rgb(255, 255, 255);padding:5px 10px;display:inline-block;margin:15px;text-decoration:none;}"+
			        "#alertDialog #dialog-button-row a:hover {background:#666; box-shadow:none;}"+
			        "body.alertIsOpen {overflow:hidden;}"+
			        "body.alertIsOpen section, body.alertIsOpen ul[attr^=jf-page-control] {filter:url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZGVmcz4KICAgICAgICA8ZmlsdGVyIGlkPSJnYXVzc2lhbl9ibHVyIj4KICAgICAgICAgICAgPGZlR2F1c3NpYW5CbHVyIGluPSJTb3VyY2VHcmFwaGljIiBzdGREZXZpYXRpb249IjEuNiIgLz4KICAgICAgICA8L2ZpbHRlcj4KICAgIDwvZGVmcz4KPC9zdmc+#gaussian_blur);-webkit-filter:blur(2px);-moz-filter:blur(2px);-ms-filter:blur(2px);-o-filter:blur(2px);filter:blur(2px);}",
			        init : function(){
			            //attach styles
			            customAlert.attachStyle();
			            //attach markup
			            this.alertDialog.hide();
			            $("body").append(customAlert.alertDialog);
			            if(customAlert.alertDialog.css("position")=="fixed" || customAlert.alertDialog.css("position")=="absolute"){
			                //found some custom style - refactor the native alert to show our custom alert instead
			                window.alert = function(msgText,btnTxt){
			                    customAlert.open(msgText,btnTxt);
			                };
			            } else {
			                console.warn("Unable to find suitable styling for the Styled Alert. Expected #alertDialog{position:[absolute|fixed]}");
			            }
			        },
			        attachStyle : function(){
			            var head = document.head || document.getElementsByTagName('head')[0],
			                style = document.createElement('style'),
			                css = customAlert.styles;
			            style.type = 'text/css';
			            if (style.styleSheet){//IE
			                style.styleSheet.cssText = css;
			            } else {
			                style.appendChild(document.createTextNode(css));
			            }
			            head.appendChild(style);
			        },
			        open : function(msgTxt,btnTxt){
			            var msg = this.alertDialog.find("#dialog-message"),
			                btn = this.alertDialog.find("#dialog-button-row a");
			            msg.html(msgTxt);
			            if(btnTxt){btn.text(btnTxt);} else {btn.text("OK");}
			            btn.off().on("click", function(){
			                customAlert.close();
			                return false;
			            });
			            customAlert.bindKeydown();
			            this.alertDialog.show();
			            $("body").addClass("alertIsOpen");
			        },
			        close : function(){
			            this.alertDialog.hide();
			            customAlert.unbindKeydown();
			            $("body").removeClass("alertIsOpen");
			        },
			        bindKeydown : function(){
			            //bind key events so we can close the dialogue
			            $(document).bind('keydown', function(e){
			                //e.preventDefault();
			                //console.log(e);
			                var key = e.which || e.keyCode || e.charCode;
			                switch (key){
			                    case 13: //rtn
			                    case 27: //esc
			                        customAlert.close();
			                        break;
			                }
			            });
			        },
			        unbindKeydown : function(){
			            //unbind key events
			            $(document).unbind('keydown');
			        }
			    };


		        setupChosen();
		        setupDocumentsUploader();

		        if($("#alertDialog").length <=0){
		            customAlert.init();
		        }

			})();

			//TODO This needs to go when all sites are up to speed with single menu
		    $('a.menu').on('click', function(e) {
		      $('body').toggleClass("openNav");
		      e.preventDefault();
		    });


  		}

  	},
  	staffList 	: {
  		init 	: function () {

            $("#staffListForm select").on('change', function() {
                $(this).parents('form').submit();
            });

            var dtColumns = "";

            if(window.hasGlobal) {
            	dtColumns = [
	                {   data: "pictureName",
	                    render: function ( data, type, row ) {
	                        if (type === 'display' ) {
	                            if (data === '' || data === null) {
	                                return '<td></td>'
	                            } else {
	                                return '<td><img style="border: 1px solid #808285;" src="/cmsUploads/team/images/' + data + '" width="60"/></td>';
	                            }
	                        }
	                        return row;
	                    }
	                },
	                { data: "firstName" },
	                { data: "lastName" },
	                {
	                    data: "edit",
	                    render: function ( data, type, row ) {
	                        if ( type === 'display' ) {
	                            return '<td><a href="'+window.contextPath+'/staff/edit/' + row.id + '" class="icon-edit">Edit</a>';
	                        }
	                        return row;
	                    }
	                }
	            ];
            } else {
            	dtColumns = [
	                {   data: "pictureName",
	                    render: function ( data, type, row ) {
	                        if (type === 'display' ) {
	                            if (data === '' || data === null) {
	                                return '<td></td>'
	                            } else {
	                                return '<td><img style="border: 1px solid #808285;" src="/cmsUploads/team/images/' + data + '" width="60"/></td>';
	                            }
	                        }
	                        return row;
	                    }
	                },
	                { data: "firstName" },
	                { data: "lastName" },
	            ];
            }

	        $("#datatable-staff").dataTable( {
	            "processing": true,
	            "serverSide": true,
	            "ordering": false,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "ajax": {
	                "url": window.contextPath + "/staff/datatable.json",
	                "data": function ( d ) {
		                if (window.filter != ""){
		                	d.filter = filter;
		                }
	                }
	            },
	            "columns": dtColumns
	        });
  		}
  	},
  	staffEdit 	: {
  		init 	: function() {
		    var showHides = $('.show-hide-pass');
		    showHides.click(function(){
		        var $this = $(this),
		            action = $this.hasClass('show') ? 'show' : 'hide',
		            inputType = action === 'show' ? 'text' : 'password';

		        $('#pw').get(0).type = inputType;
		        showHides.toggle();
		    });
  		}
  	},
  	pfList 	: {
  		init 	: function () {

            function format(d) {
                var tableData = '';

                for (var i = 0; i < d.notes.length; i++) {
                    var note = d.notes[i];
                    tableData += '<tr id="note_' + note.id + '"><td>' + note.note + '</td><td>' + note.staffFirstName + ' ' + note.staffLastName + '</td><td>' + note.dateCreated + '</td><td style="width:8%;"><a class="modal-link icon-edit" href="' + window.contextPath + '/policies/edit-note?id=' + note.id + '"><div class=""><svg class="icon-edit"><use xlink:href="#icon-edit"></use></svg></div></a></td><td style="width:8%;"><a id="deleteFormNote" class="icon-bin" href="' + window.contextPath + '/policies/delete-note" data-id="' + note.id + '"><div class=""><svg class="icon-bin"><use xlink:href="#icon-bin"></use></svg></div></a></td></tr>'
                }

                return '<div style="display: block; text-align: right; margin-bottom: 20px;"><a href="' + window.contextPath + '/policies/create-note?formYear=' + d.formYear + '" id="createNote" data-id="' + d.id + '"><button>Create note</button></a></div>' +
                    '<table cellpadding="5" cellspacing="0" border="0" style="width:100%;">' +
                    '<tbody>' + tableData + '</tbody></table>';
            }

            function formatLogs(d , func) {
                var result = $.ajax(window.contextPath + '/policies/ajax_logs/' + d.id + "?formYear=" + d.formYear)
                    .done(function(data) {
                        // do nothing here

                        var tableAppended = '';

                        for (var i = 0; i < data.logs.length; i++) {
                            var log = data.logs[i];

                            tableAppended += '<tr><td>' + log.description + '</td><td>' + log.dateTime + '</td></tr>';
                        }

                        var tableData = '';

                        if (data.logs.length < 1) {
                            tableData += '<tr><td>No activity for this proposal form</td></tr>';
                        } else {
                            tableData += '<table cellpadding="5" cellspacing="0" border="0" style="width:100%;">' +
                                '<tbody>' + tableAppended + '</tbody></table>';
                        }

                        func(tableData);
                    })
                    .fail(function() {
                        alert('Failed to load activity log for this proposal form');
                    });
            }

            function formatFiles(d , func) {

                $.ajax(window.contextPath + '/policies/files/' + d.id + '?formYear=' + d.formYear)
                    .done(function(data) {

                        console.warn(data);

                        var tableAppended = '';

                        for (var i = 0; i < data.length; i++) {
                            var fl = data[i];
                            tableAppended += '<tr><td><a href="' + window.contextPath + '/file-dispatcher/get?formID=' + d.id + '&name=' + fl + '&formYear=' + d.formYear + '">' + fl + '</a></td></tr>';
                        }

                        /*
                        Updated formatFiles function to correctly wrap the file list tr's in a table.
                        var tableData = '';

                        console.log(data);

                        if (data.length < 1) {
                            tableData += '<tr><td>No files for this proposal form</td></tr>';
                        } else {
                            tableData += '<table cellpadding="5" cellspacing="0" border="0" style="width:100%;">' +
                                '<tbody>' + tableAppended + '</tbody></table>';
                        } */

                        if (data.length < 1) {
                            tableAppended = '<tr><td>No files for this proposal form</td></tr>';
                        }

                        var tableData = '<table cellpadding="5" cellspacing="0" border="0" style="width:100%;">' +
                            '<tbody>' + tableAppended + '</tbody></table>';

                        func(tableData);
                    })
                    .fail(function() {
                        alert('Unable to load files for this proposal form');
                    });
                return false; //to prevent following the link
            }

  			var table;

		    function changeFormStatus() {
		        var tblRows = table.rows({ selected: true });
		        var tblRowsSelectedData = tblRows.data();

		        $('#bulkStatusChange input[name=formID]').remove();

		        if (tblRows.count() > 0) {
		            var newStatus = $('#status').find(":selected").text();

		            if (confirm('Are you sure you want to change the status of ' + tblRows.count() + ' form(s) to ' + newStatus + '?')) {
		                for (var i = 0; i < tblRowsSelectedData.length; i++) {
		                    var row = tblRowsSelectedData[i];

		                    $('#bulkStatusChange').append('<input type="hidden" name="formID" value="' + row.id + '" />');
		                }

		                $('#bulkStatusChange').submit();
		            }
		        } else {
		            alert('Please select some rows using the checkbox provided.');
		        }
		    }

		    /**
		     * getDatatableColumns()
		     * This configures the columns for datatables based on variables in the window object set from server-side in /proposalforms/list.ftl
		     */
		    function getDatatableColumns(){

		    	var columns = [];

                console.log("formYear",(window.pfListFilter.formYear != "2015"));
		    	// Select checkbox
		    	if (window.pfListFilter.formYear != "2015"){
		    		columns.push(
		    			{
		                    render: function(d,t,r) {
		                        return '';
		                    },

		                    orderable: false,
		                    className: 'select-checkbox',
		                    targets:   0
		                }
		    		);
		    	}

		    	//Policy ID
		    	columns.push({ data: "id" });

		    	//Client Name (Company name in most cases)
                if (!window.ajax) {
                    columns.push({data: "companyName"});
                }

		    	//Practice Size
                if (!window.ajax) {
                    columns.push({data: "majorOrStandard"});
                }

                //Limit of Indemnity
                columns.push({data: "limitOfIndemnity"});

		    	//Status
		    	columns.push(
		    		{
	                    data: "status",
	                    render: function ( data, type, row ) {
	                        if ( type == 'display' ) {
	                            var capitalizedStatus;

	                            if (row.status.toLowerCase() === 'open') {
	                                capitalizedStatus = 'Open';
	                            } else if (row.status.toLowerCase() === 'submitted') {
	                                capitalizedStatus = 'Submitted';
	                            } else if (row.status.toLowerCase() === 'locked') {
	                                capitalizedStatus = 'Locked';
	                            } else if (row.status.toLowerCase() === 'inactive') {
	                                capitalizedStatus = 'Inactive';
	                            } else if (row.status.toLowerCase() === 'started') {
	                                capitalizedStatus = 'Started';
	                            } else if (row.status.toLowerCase() === 'returned') {
	                                capitalizedStatus = 'Returned';
	                            } else if (row.status.toLowerCase() === 'retracted') {
	                                capitalizedStatus = 'Retracted';
	                            } else if (row.status.toLowerCase() === 'complete') {
	                                capitalizedStatus = 'Complete';
	                            } else if (row.status.toLowerCase() === 'not-renewed') {
	                                capitalizedStatus = 'Not Renewed';
	                            }

	                            if (row.formYear === '2015') {
	                                return capitalizedStatus;
	                            }
	                            if (row.status.toLowerCase() === 'submitted') {
	                                return '<select style="cursor:pointer;" id="statusChange"><option selected>Submitted</option><option>-</option><option value="return-' + row.id +'-' + row.formYear + '">Return Form</option><option value="complete-' + row.id +'-' + row.formYear + '">Complete Form</option><option value="notrenewed-' + row.id +'-' + row.formYear + '">Not Renewed</option></select>';
	                            } else {
	                                if (row.status.toLowerCase() == 'not-renewed') {
	                                    return 'Not Renewed';
	                                } else {
	                                    return '<select style="cursor:pointer;" id="statusChange"><option selected>' + capitalizedStatus + '</option><option value="notrenewed-' + row.id +'-' + row.formYear + '">Not Renewed</option></select>';
	                                    return capitalizedStatus;
	                                }
	                            }
	                        }
	                        return row;
	                    },
	                    orderable: true
					}
		    	);

		    	//Expiry Date
		    	columns.push({data: "expiryDate"});

                //Display insurer when required (at time of writing, Solicitors only)
                if(window.hasHannoverSend) {
                    columns.push(
                        {
                            data: "leadInsurer",
                            orderable: false,
                            render: function ( data, type, row ) {
                                if (row.formYear == 2015) {
                                    return '';
                                }

                                //if (row.leadInsurer.toLowerCase() == 'hannover') {
                                //console.log(row.hannoverExportPending);
                                if (row.status.toLowerCase() == 'returned' || row.status.toLowerCase() == 'retracted') {
                                    return 'N/A';
                                } else if (row.hannoverExportPending === 1) {
                                    return 'Pending';
                                } else if (row.hannoverExportPending === 0) {
                                    return 'Sent';
                                } else {
                                    return '<a href="'+window.contextPath+'/policies/hannover?formID=' + row.id + '">Send</a>'
                                }
                                //} else {
                                //    return 'N/A';
                                //}
                            }
                        }
                    );
                }

                if (window.clientConsoleEnabled) {
                    //Edit Button
                	//Notes Button
					//Activity Button
					//Files Button
                    columns.push(
                        {
                            "className":      'actions',
                            "orderable":      false,
                            data: "edit",
                            render: function ( data, type, row ) {
                                if ( type === 'display' ) {
                                    return '<td><ul class="dt-actions"><li class="dt-actions-item"><a href="'+ window.contextPath +'/policies/edit/' + row.id + '" title="Edit Policy"><svg class="icon-edit"><use xlink:href="#icon-edit"></use></svg></a></li><li class="dt-actions-item"><a href="#" class="details-control js-viewnotes" title="View Notes"><svg class="icon-notes"><use xlink:href="#icon-notes"></use></svg></a></li><li class="dt-actions-item"><a href="#" class="icon-activity activity-control js-viewactivity"  title="View Activity"><svg class="icon-activity"><use xlink:href="#icon-activity"></use></svg></a></li><li class="dt-actions-item"><a href="#" class="files-control js-viewfiles"  title="View Uploaded Files"><svg class="icon-view-files"><use xlink:href="#icon-view-files"></use></svg></a></li></ul></td>';
                                }
                                return row;
                            }
                        }
                    );
                } else{
                    //Edit Record
                    columns.push(
                        {
                            data: "edit",
                            render: function ( data, type, row ) {
                                if ( type === 'display' ) {
                                    return '<td><a href="'+window.contextPath+'/policies/edit/' + row.id + '">Edit</a></td>';
                                }
                                return row;
                            }, orderable: false
                        }
                    );

					//Notes Button
					columns.push(
						{
							"className":      'details-control',
							"orderable":      false,
							"data":           null,
							"defaultContent": '<a href="#" class="icon-notes js-viewnotes"><div class=""><svg class="icon-notes"><use xlink:href="#icon-notes"></use></svg></div></a>'
						}
					);

					//Activity Button
					columns.push(
						{
							"className":      'activity-control',
							"orderable":      false,
							"data":           null,
							"defaultContent": '<a href="#" class="icon-activity js-viewactivity"><div class=""><svg class="icon-activity"><use xlink:href="#icon-activity"></use></svg></div></a>'
						}
					);

					//Files Button
					columns.push(
						{
							"className":      'files-control',
							"orderable":      false,
							"data":           null,
							"defaultContent": '<a href="#" class="js-viewfiles">View Files</a>'
						}
					);

				}


				//View Prop Form Link
				columns.push(
					{
	                    data: "view",
	                    render: function ( data, type, row ) {
	                        if ( type === 'display' ) {
	                            //return '<td><a href="'+window.contextPath+'/policies/' + row.id + '?formYear=' + row.formYear + '">View</a></td>';
	                            return '<td><a href="'+window.contextPath+'/policies/' + row.id + '">View</a></td>';
	                        }
	                        return row;
	                    },
	                    orderable: false
	                }
				);

				//Quotation
				if(window.quotesEnabled) {
					columns.push(
						{
		                    data: "quotation",
		                    render: function ( data, type, row ) {
		                        if ( type === 'display' ) {
		                            //return '<td><a href="'+window.contextPath+'/policies/' + row.id + '?formYear=' + row.formYear + '">View</a></td>';
		                            return '<td data-row-quotation="'+row.quotation+'">' + ((parseInt(row.quotation)>0)?"Yes":"No") + '</td>';
		                        }
		                        return row;
		                    },
		                    orderable: false
		                }
					);
				}

		    	return columns;
		    }

		    /**
		     * getDataTablesSelect()
		     * This sets the "select" attribute of datatables.
		     */
		    function getDataTablesSelect() {
		    	if (window.pfListFilter.formYear !== "2015") {
		    		return false;
		    	} else{
		    		return {
		                style:    'multi',
		                selector: 'td:first-child'
		            };
		    	}
		    }

	    	table = $("#datatable-policies").DataTable( {
	            "order": [[ 1, "desc" ]],
	            rowId: 'id',
	            "select": {
	                style:    'multi',
	                selector: 'td:first-child'
	            },
	            "processing": true,
	            "serverSide": true,
	            "ordering": true,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "ajax": {
	                "url": window.contextPath + "/policies/datatable.json",
	                "data": function ( d ) {
		               	if(window.pfListFilter.StaffId != ""){
		                    d.filterStaffId = window.pfListFilter.staffId;
		                }
		                if(window.pfListFilter.status != ""){
		                    d.statusFilter = window.pfListFilter.status;
		                }
		                if(window.pfListFilter.formYear != ""){
		                    d.formYearFilter = window.pfListFilter.formYear;
		                }
		                if(window.pfListFilter.renewalYear != ""){
		                    d.renewalYearFilter = window.pfListFilter.renewalYear;
		                }
                        if(window.pfListFilter.subPolicies != ""){
                            d.subPoliciesFilter = window.pfListFilter.subPolicies;
                        }
                        if(window.pfListFilter.siaAbove != ""){
                            d.filterSiaAbove = window.pfListFilter.siaAbove;
                        }
                        if(window.pfListFilter.siaBelow != ""){
                            d.filterSiaBelow = window.pfListFilter.siaBelow;
                        }
                        if(window.pfListFilter.lossRatioAbove != ""){
                            d.filterLossRatioAbove = window.pfListFilter.lossRatioAbove;
                        }
                        if(window.pfListFilter.lossRatioBelow != ""){
                            d.filterLossRatioBelow = window.pfListFilter.lossRatioBelow;
                        }
                        if(window.pfListFilter.lossesAbove != ""){
                            d.filterLossesAbove = window.pfListFilter.lossesAbove;
                        }
                        if(window.pfListFilter.lossesBelow != ""){
                            d.filterLossesBelow = window.pfListFilter.lossesBelow;
                        }
                        if(window.pfListFilter.revenueAbove != ""){
                            d.filterRevenueAbove = window.pfListFilter.revenueAbove;
                        }
                        if(window.pfListFilter.revenueBelow != ""){
                            d.filterRevenueBelow = window.pfListFilter.revenueBelow;
                        }
                        if(window.pfListFilter.tcfIndicator != "") {
                        	d.tcfIndicatorFilter = window.pfListFilter.tcfIndicator;
						}
	                }, orderable: false
	            },
	            "columns": getDatatableColumns()
	        });

            $("#statusFilter select, #statusFilter input").on('change', function() {
                //Set the window variable for the datatable to pick up and send in the ajax all
				window.pfListFilter[$(this).attr('name')] = $(this).val();

				//Redraw datatable
                table.draw();
            });

	        var tblTbody = $('#datatable-policies');

	        tblTbody.on('click', '.js-viewnotes', function () {
	        	console.log("VIEW NOTES");
	            var tr = $(this).closest('tr');
	            var row = table.row( tr );

	            if ( row.child.isShown() ) {
	                row.child.hide();
	                tr.removeClass('shown');
	            }
	            else {
	                row.child( format(row.data()) ).show();
	                tr.addClass('shown');
	            }
	        });

	        tblTbody.on('click', '.js-viewactivity', function() {
                console.log("VIEW ACTIVITY");
	            var tr = $(this).closest('tr');
	            var row = table.row( tr );

	            if ( row.child.isShown() ) {
	                row.child.hide();
	                tr.removeClass('shown');
	            }
	            else {
	                formatLogs(row.data(), function(data) {
	                    row.child( data ).show();
	                    tr.addClass('shown');
	                });
	            }
	        });

	        tblTbody.on('click', '.js-viewfiles', function() {
                console.log("VIEW FILES");
	            var tr = $(this).closest('tr');
	            var row = table.row( tr );

	            if ( row.child.isShown() ) {
	                row.child.hide();
	                tr.removeClass('shown');
	            }
	            else {
	                formatFiles(row.data(), function(data) {
	                    row.child( data ).show();
	                    tr.addClass('shown');
	                });
	            }
	        });

	        var bdy = $('body');

	        bdy.on('click', '#saveFormNote', function(e) {
	            e.preventDefault();

	            var a = $(this).attr('href');
	            var i = $(this).data('id');
	            var text = $('#notetext_' + i).val();

	            $.post(a, { 'id': i, 'text': text }, function() {

	            })
	                    .done(function() {
	                        alert( "This note has been updated" );
	                    })
	                    .fail(function() {
	                        alert( "Unable to save note" );
	                    });
	        });

	        bdy.on('click', '#deleteFormNote', function(e) {
	            e.preventDefault();

	            var a = $(this).attr('href');
	            var i = $(this).data('id');

	            $.post(a, { 'id': i }, function() {

	            })
	                    .done(function() {
	                        $('#note_' + i).fadeOut(500, function() {
	                            $(this).remove();
	                        })
	                    })
	                    .fail(function() {
	                        alert( "Unable to delete note" );
	                    });
	        });

	        bdy.on('click', '#createNote', function(e) {
	            e.preventDefault();

	            var a = $(this).attr('href');
	            var i = $(this).data('id');

	            $.post(a, { 'id': i }, function() {

	            }).done(function(data) {
	                var noteId = data.noteId;

	                modalBob(window.contextPath + '/policies/edit-note?id=' + noteId);
	            })
	                    .fail(function() {
	                        alert( "Unable to create a new note" );
	                    });
	        });

	        bdy.on('change', '#statusChange', function(e) {
	            var vlSpl = this.value.split('-');
	            var vl = vlSpl[0];
	            var id = vlSpl[1];
	            var year = vlSpl[2];

	            if (vl === 'return') {
	                window.location.href = window.contextPath + '/policies/return/' + id;
	            } else if (vl === 'complete') {
	                window.location.href = window.contextPath + '/policies/complete/' + id;
	            } else if (vl == 'notrenewed') {
	                window.location.href = window.contextPath + '/policies/notrenewed/' + id;
	            }
	        });
  		}
  	},
  	pfEdit 	: {
  		init 	: function() {
  			$('#majorOrStandard').chosen();
	        $('#sector').chosen();

	        //Below is ignored for now, webshims has not been moved across as it looks to reference a bunch of it's own JS libraries that need ot be on page/on the server
	        // webshim.activeLang('en-GB');
	        // webshims.setOptions('forms-ext', {replaceUI: 'auto', types: 'date range number'});
	        // webshims.polyfill('forms forms-ext');

            //show/hide forquoting bit depending on the selected questionset
            $('#policyQuestionSet').change(function(){
                var $selectedOption = $(this).find(":selected");
                if($selectedOption && $selectedOption.attr("data-forquotingdefault")==="1"){
                    $("#forQuotingContainer").show().find("#forQuoting").prop("checked","checked").attr("checked","checked");
                } else {
                    $("#forQuotingContainer").hide().removeProp("checked").removeAttr("checked");
                }
            }).change();
  		}
  	},
  	pfCreate 	: {
  		init 	: function() {
  			$('#majorOrStandard').chosen();

  			//Below is ignored for now, webshims has not been moved across as it looks to reference a bunch of it's own JS libraries that need ot be on page/on the server
			// webshim.activeLang('en-GB');
			// webshim.setOptions('forms-ext', {replaceUI: 'auto', types: 'date range number'});
	  		// webshim.polyfill('forms forms-ext');

	        $('#insurance').change(function() {
	            var selVal = $(this).val();
	            if (selVal == '' || selVal == '-') {
	                $('#revealQuestionSet, #submitBlock').hide();
	            } else {
	                var ctx = window.contextPath;
	                $.ajax(ctx + '/policies/policyQuestionSets.json?insurancesID=' + selVal, {
	                    success: function(data) {
	                        // reset
	                        $('#policyQuestionSet').find('option').remove();
	                        // fetch
	                        $.each(data, function(index, val) {
	                            //$('#policyQuestionSet').append('<option data-year="2016" data-insurancesid="' + val.insurancesid + '" data-insurancetypeid="' + val.insurancetypeid + '" value="' + val.mainid + '">' + val.insurancetype + ' (Year 2016)</option>');
	                            //$('#policyQuestionSet').append('<option value="' + val.id + '">#' + val.id + ' - ' + val.title + '</option>');
	                            $('#policyQuestionSet').append('<option value="' + val.id + '" data-forquotingdefault="' + val.forquotingdefault + '">#' + val.id + ' - ' + val.title + '</option>');
	                        });
	                        // append
	                        $('#policyQuestionSet').append('<option value="-1">NONE</option>');
	                        //$('#policyQuestionSet').find('option').first().prop('selected', true);
	                        $('#policyQuestionSet').find('option').first().prop('selected', true).change();

	                        // trigger chosen updated
	                        $('.chosen-style').trigger("chosen:updated");
	                        $(window).trigger("resize");
	                        // show
	                        $('#revealQuestionSet, #submitBlock').show();
	                    }
	                });
	            }
	        });

            //show/hide forquoting bit depending on the selected questionset
            $('#policyQuestionSet').change(function(){
                var $selectedOption = $(this).find(":selected");
                if($selectedOption && $selectedOption.attr("data-forquotingdefault")==="1"){
                    $("#forQuotingContainer").show().find("#forQuoting").prop("checked","checked").attr("checked","checked");
                } else {
                    $("#forQuotingContainer").hide().removeProp("checked").removeAttr("checked");
                }
            }).change();
  		}
  	},
  	insurancesList 	: {
  		init : 	function() {
	        $("#datatable-insurances").dataTable( {
	            "processing": true,
	            "serverSide": true,
	            "ordering": true,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "ajax": {
	                "url": window.contextPath + "/insurances/datatable.json"
	            },
	            "columns": [
	                { data: "id" },
	                { data: "companyName" },
	                { data: "insuranceType" },
	                {
	                    data: "edit",
	                    render: function ( data, type, row ) {
	                        if ( type === 'display' ) {
	                            return '<a href="'+window.contextPath+'/insurances/edit/' + row.id + '" class="icon-edit">Edit</a>';
	                        }
	                        return row;
	                    },
                        orderable : false
	                }
	            ]
	        });
  		}
  	},
  	insurancesEdit 	: {
  		init 	: function () {
 			$('#majorOrStandard').chosen();
		    $('#sector').chosen();


		    //Below is ignored for now, webshims has not been moved across as it looks to reference a bunch of it's own JS libraries that need ot be on page/on the server
		    // webshim.activeLang('en-GB');
		    // webshims.setOptions('forms-ext', {replaceUI: 'auto', types: 'date range number'});
		    // webshims.polyfill('forms forms-ext');

		    var filds = $('#fields');
		    var frm = $('div.linkedFormAddNew').clone();

		    $("[name=staff]").chosen();
		    $("[name=role]").chosen();

		    var v;

		    $('div.jsonForm').on('click', 'input[type=submit]', function() {
		        v = $(this).attr('name');
		    });

		    $('div.jsonForm').on('submit', 'form', function(e) {
		        e.preventDefault();
		    	console.log("Initialised");

		        var ths = $(this);
		        ths.addClass("disabled").find("input[type=submit]").prop("disabled", true).prop("readonly", true);

		        var data = $(this).serialize() + "&method=" + v;

		        if(window.csrf && Object.keys(window.csrf).length > 0){
		            data = data + window.csrf.parameterName + "=" + window.csrf.token;
		        }

		        $.post(window.contextPath + "/insurances/edit/ajax_sectors", data)
		                .done(function(data) {
		                    if (data.success) {


		                        if (data.method === "add") {
		                            var newFrm = frm.clone();
		                            filds.append(newFrm);
		                            ths.removeClass('linkedFormAddNew').addClass('linkedForm');
		                            ths.find('input[type=submit]').val('Save').attr('name', 'modifyInsurancesStaff').after($('<input id="submit" type="submit" name="deleteInsurancesStaff" value="-" style="pointer-events: auto;">'));
		                            ths.find('input[name=policyRefId]').val(data.id);

		                            newFrm.find('[name=role]').chosen();
		                            newFrm.find('[name=staff]').chosen();
		                        } else if (data.method === "delete") {
		                            ths.remove();
		                        }
		                    } else {
		                    	if(data.msg && (data.msg).trim().length) { //if msg is truthy
		                    		alert(data.msg);
								}else {
                                    alert("Something went wrong, please try again or refresh the page.");
								}
		                    }
		                })
		                .fail(function() {
		                    alert("Unable to perform request, don't add duplicate sectors or maybe check your internet connection");
		                })
		                .always(function() {
		                    ths.removeClass("disabled").find("input[type=submit]").prop("disabled", false).prop("readonly", false);
		                });
		        return false;
		    });
  		}
  	},
  	insurancesCreate 	: {
  		init 	: function() {
  			// Below is ignored for now, webshims has not been moved across as it looks to reference a bunch of it's own JS libraries that need ot be on page/on the server
  			// webshim.activeLang('en-GB');
     		// webshim.setOptions('forms-ext', {replaceUI: 'auto', types: 'date range number'});
     		// webshim.polyfill('forms forms-ext');
  		}
  	},
  	insurancesCreate2 	: {
  		init 	: function() {
  			$("[name=staff]").chosen({disable_search_threshold: 10});
    		$("[name=role]").chosen({disable_search_threshold: 10});
  		}
  	},
  	documents : {/*deprecated - should be documentsList */
  		init 	: function() {
	        $("#filters select").on('change', function() {
	            $(this).parents('form').submit();
	        });

            /**
             * getDatatableColumns()
             * This configures the columns for datatables based on variables in the window object set from server-side in /documents/list.ftl
             */
            function getDatatableColumns(){

                var columns = [];

                //Document ID Column
                columns.push({ data: "id", orderable: false });

                //Company/Client Name
                if (!window.ajax) {
                    columns.push({data: "companyName", orderable: false});
                }

                //filename
                columns.push({ data: "fileName", orderable: false });

                //Document Type
				columns.push({ data: "documentType", orderable: false });

                //Insurance Type
                columns.push({ data: "insuranceType", orderable: false });

                //Year
                columns.push({
                    data: "year",
                    render: function ( data, type, row ) {
                        if ( type === 'display' ) {
                            return '<td>2016</td>';
                        }
                        return row;
                    }, orderable: false
                });

                //Delete
                columns.push({
                    data: "delete",
                    render: function ( data, type, row ) {
                        if ( type === 'display' ) {
                            return '<td><ul class="dt-actions"><li class="dt-actions-item"><a href="'+window.contextPath+'/documents/delete/' + row.id + '"><svg class="icon-bin"><use xlink:href="#icon-bin"></use></svg></a></li><li class="dt-actions-item"><a href="'+window.contextPath+'/documents/download?file=' + row.id + '"><svg class="svg-icon"><use xlink:href="#download"></use></svg></a></li></ul></td>';
                        }
                        return row;
                    }, orderable: false
                });

                return columns;
            }


            table = $("#datatable-documents").DataTable( {
	            "order": [[ 1, "desc" ]],
	            rowId: 'id',
	            "processing": true,
	            "serverSide": true,
	            "ordering": false,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "ajax": {
	                "url": window.contextPath+"/documents/datatable.json",
	                "data": function ( d ) {
		                if(window.insuranceTypeFilter != ""){
		                    d.insuranceTypeFilter = window.insuranceTypeFilter;
		                }
		                if(window.isClaims) {
                            if(window.documentTypeFilter != ""){
                                d.documentTypeFilter = window.documentTypeFilter;
                            }
						}
		                if(window.yearFilter != ""){
		                    d.yearFilter = window.yearFilter;
		                }
		                if(window.clientFilter != ""){
		                    d.clientFilter = window.insuranceTypeFilter;
		                }
	                }, orderable: false
	            },
	            "columns": getDatatableColumns()
	        });
  		}
  	},
    documentsList : {
        init 	: function() {
            /**
             * getDatatableColumns()
             * This configures the columns for datatables based on variables in the window object set from server-side in /documents/list.ftl
             */
            function getDatatableColumns(){

                var columns = [];

                //Document ID Column
                columns.push({data: "id", orderable: true});

                //Company/Client Name
                if (!window.ajax) {
                    columns.push({data: "companyName", orderable: true});
                }

                //filename
                columns.push({data: "fileName", orderable: true});

                console.log("is claims set");
                console.log(window.isClaimsSet);

                //Document Type
                if (!window.isClaimsSet && !window.isBenchmarkingReportSet) {
                    columns.push({data: "documentType", orderable: true});
                }

                //Insurance Type
                columns.push({data: "insuranceType", orderable: true});

                //Year
                columns.push({data: "year", orderable: true});

                var deleteControllerPath="/documents/delete/";

                if(window.isClaimsSet) {
                	deleteControllerPath = "/claimsSnapshots/delete/"
				}

				if(window.isBenchmarkingReportSet) {
                	deleteControllerPath = "/benchmarkingReport/delete/"
				}



                //Delete
                columns.push({
                    data: "delete",
                    render: function ( data, type, row ) {
                        if ( type === 'display' ) {
                            return '<td><ul class="dt-actions"><li class="dt-actions-item"><a href="'+ window.contextPath + deleteControllerPath + row.id + '"><svg class="icon-bin"><use xlink:href="#icon-bin"></use></svg></a></li><li class="dt-actions-item"><a href="'+window.contextPath+'/documents/download?file=' + row.id + '"><svg class="svg-icon"><use xlink:href="#download"></use></svg></a></li></ul></td>';
                        }
                        return row;
                    }, orderable: false
                });

                // console.log("is benchmarking set");
                // console.log(window.isBenchmarkingReportSet);

                return columns;
            }


            table = $("#datatable-documents").DataTable( {
                "order": [[ 1, "desc" ]],
                rowId: 'id',
                "processing": true,
                "serverSide": true,
                "ordering": true,
                "stateSave": true,
                "lengthMenu": [ 15, 25, 50, 75, 100 ],
                "ajax": {
                    "url": window.contextPath+"/documents/datatable.json",
                    "data": function ( d ) {
                        if(window.insuranceTypeFilter != ""){
                            d.insuranceTypeFilter = window.insuranceTypeFilter;
                        }
                        if(window.documentTypeFilter != ""){
                            d.documentTypeFilter = window.documentTypeFilter;
                        }
                        if(window.yearFilter != ""){
                            d.yearFilter = window.yearFilter;
                        }
                        if(window.clientFilter != ""){
                            d.clientFilter = window.clientFilter;
                        }
                        if(window.isClaimsSet) {
                            d.isClaimsSet = true;
						}
						if(window.isBenchmarkingReportSet) {
                        	d.isBenchmarkingReportSet = true;
						}
                    }, orderable: false
                },
                "columns": getDatatableColumns()
            });
            $("#filters select").on('change', function() {
                table.state.clear();
                $(this).parents('form').submit();
            });
        }
    },
    documentsFileUploadV2     : {
        init    : function () {
            /* pick a client (from all for this site) > pick an insuranceType (from all for this site) > pick a year (current pre-selected) > fetch and reveal matching policies (may be none) */

            var $clientfields = $('#clientfields'),
                $client = $('#client'),
                $insurancefields = $('#insurancefields').hide(),
                $insurancetype = $('#insuranceType'),
                $yearfields = $('#yearfields').hide(),
                $year = $('#year'),
                $policyfields = $('#policyfields').hide(),
                $policies = $('#form'),//weird id value but I don't want to change it as it affects the drag n drop file uploader and I cant be arsed to fight with it.
                $fileUploadBlock = $('#fileUploadBlock').hide(),
                $docuploadform = $('#docuploadform :input:not(#form,#userDefinedEmailNotificationFlag)'),
                $userDefinedDate = $('#userDefinedDate').hide(),
                $userDefinedEmailNotification = $('#userDefinedEmailNotification').hide(),
				$date = $('#date');


            $docuploadform.on("change",function(e) {
                if(e.target)
                var clientID = parseInt($client.val(),10) || 0;
                var insuranceTypeID = parseInt($insurancetype.val(),10) || 0;
                var formYear = parseInt($year.val(),10) || 0;
                if (clientID>0) {
                    $insurancefields.show();
                    $yearfields.show();
                    $userDefinedDate.show();
                    $userDefinedEmailNotification.show();
                } else {
                    $insurancefields.hide();
                    $yearfields.hide();
                    $userDefinedDate.hide();
                    $userDefinedEmailNotification.hide();
                }
                if (clientID>0 && insuranceTypeID>0) {
                    $policies.trigger("populate");
                    $policyfields.show();
                    $fileUploadBlock.show();
                } else {
                    $policyfields.hide();
                    $fileUploadBlock.hide();
                }
            });

            $policies.on("populate",function() {
                var clientID = parseInt($client.val(),10) || 0;
                var insuranceTypeID = parseInt($insurancetype.val(),10) || 0;
                var formYear = parseInt($year.val(),10) || 0;
                var $this = $(this);
                var ctx = window.contextPath;
                $.ajax(ctx + '/documents/policiesforinsurancetypebyclientandyear.json?clientID=' + clientID + '&insuranceTypeID=' + insuranceTypeID + '&formYear=' + formYear, {
                    success: function(data) {
                        // reset
                        $this.find('option').remove().end().append('<option value="">Please Select</option>');
                        var optlen = 0, optval='';
                        $.each(data, function(index, val) {
                            optlen++; optval = val.mainid;
                            $this.append('<option data-year="' + val.formyear + '" data-insurancesid="' + val.insurancesid + '" data-insurancetypeid="' + val.insurancetypeid + '" value="' + val.mainid + '">' + val.policyreference + '</option>');
                        });
                        $this.append('<option value="manual">Manual / None (Not associated with a specific proposal form)</option>');
                        //if there's only one option we should pre select it
                        if(optlen==1){
                            $this.val(optval);
                        }
                        // trigger chosen updated
                        $('.chosen-style').trigger("chosen:updated");
                    }
                });
            });

        }
    },
  	documentsFileUpload 	: {
  		init 	: function () {
	        $('#client').change(function() {
	            var selVal = $(this).val();
	            if (selVal == '' || selVal == '-') {
	                $('#revealPropform').hide();
	                $('#revealManual').hide();
	                $('#fileUploadBlock').hide();
	            } else {
	                var ctx = window.contextPath;
	                $.ajax(ctx + '/documents/forms.json?clientID=' + selVal, {
	                    success: function(data) {
	                        // reset
	                        $('#form').find('option').remove().end().append('<option value="manual">Manual</option>').val('manual');

	                        $.each(data, function(index, val) {
	                            //$('#form').append('<option data-year="2016" data-insurancesid="' + val.insurancesid + '" data-insurancetypeid="' + val.insurancetypeid + '" value="' + val.mainid + '">' + val.insurancetype + ' (Year 2016)</option>');
	                            //$('#form').append('<option data-year="2016" data-insurancesid="' + val.insurancesid + '" data-insurancetypeid="' + val.insurancetypeid + '" value="' + val.mainid + '">' + val.policyreference + '</option>');
	                            $('#form').append('<option data-year="' + val.formyear + '" data-insurancesid="' + val.insurancesid + '" data-insurancetypeid="' + val.insurancetypeid + '" value="' + val.mainid + '">' + val.policyreference + '</option>');
	                        });

	                        // trigger chosen updated
	                        $('.chosen-style').trigger("chosen:updated");

	                        $('#revealPropform').show();
	                        $('#revealManual').show();
	                        $('#fileUploadBlock').show();
	                    }
	                });
	            }
	        });

	        $('#form').change(function() {
	           var selVal = $(this).val();

	            if (selVal == 'manual') {
	                $('#revealManual').show();
	            } else {
	                $('#revealManual').hide();
	            }

	            $('#fileUploadBlock').show();
	        });

  		}
  	},
  	clientList 	: {
  		init 	: function () {
            var tblSelec = $("#datatable-clients");

	        var dtColumns = [];

            if(window.clientConsoleEnabled){
                pathToEdit = "/clients/console/";
            } else{
                pathToEdit =  "/clients/edit/";
            }

            //console.log("CONSOLEON",window.clientConsoleEnabled);

	        //console.log(window.hasGlobal);

            // client Name
			dtColumns.push({ data: "companyName" });

            // Address Line 1
			dtColumns.push({ data: "addressLine1" });

			// Postcode
			dtColumns.push({ data: "postcode" });

			//Phone number
			dtColumns.push({ data: "telephone" });

			// Selected checkbox for those who have access
			if(window.hasGlobal) {
                dtColumns.push({
                        data: "checkbox",
                        render: function (data, type, row) {
                            if (type === 'display') {
                                return '<td><input type="checkbox" name="check" value="' + row.id + '"/></td>';
                            }
                            return row;
                        },
                        orderable : false
                    }
                )
            };

			// LSOS requires status also
			if(window.siteName == "lsos") {
                dtColumns.push({ data: "status" })
			}

            // Manage button
            dtColumns.push(
                {
                    data: "edit",
                    render: function ( data, type, row ) {
                        if ( type === 'display' ) {
                            return '<ul class="dt-actions"><li class="dt-actions-item"><a href="'+window.contextPath + pathToEdit + row.id + '" title="Manage"><svg class="icon-edit"><use xlink:href="#icon-edit"></use></svg></a></li></ul>';
                        }
                        return row;
                    },
                    orderable: false
                }
			);

           tblSelec = $("#datatable-clients").DataTable( {

	            "processing": true,
	            "serverSide": true,
	            "ordering": true,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "createdRow": function ( row, data, index ) {

	                if (data.deleted) {
	                    var rw = $('td', row);

	                    rw.eq(0).addClass('deleted');
	                    rw.eq(1).addClass('deleted');
	                    rw.eq(2).addClass('deleted');
	                    rw.eq(3).addClass('deleted');
	                }
	            },
	            "ajax": {
	                "url": window.contextPath + "/clients/datatable.json",
	                "data": function ( d ) {

	                	if(window.filter != ""){
		                    d.filter = window.filter;
		                }
		                if(window.staffID != ""){
		                    d.staffID = window.staffID;
		                }
		                if(window.insuranceID != ""){
		                    d.insuranceID = window.insuranceID;
		                }
                        if(window.status != ""){
                            d.status = window.status;
                        }
						if(window.type != ""){
							d.type = window.type;
						}
						if(window.criminalPract != ""){
							d.criminalPract = window.criminalPract;
						}
						if(window.SIAabove != "") {
							d.SIAabove = window.SIAabove;
						}
						if(window.SIAbelow != "") {
							d.SIAbelow = window.SIAbelow;
						}
						if(window.LossRatioAbove != ""){
							d.LossRatioAbove = window.LossRatioAbove;
						}
						if(window.LossRatioBelow != ""){
							d.LossRatioBelow = window.LossRatioBelow;
						}
						if(window.RevenueAbove != ""){
							d.RevenueAbove = window.RevenueAbove;
						}
						if(window.RevenueBelow != ""){
							d.RevenueBelow = window.RevenueBelow;
						}
						if(window.LossesAbove != ""){
							d.LossesAbove = window.LossesAbove;
						}
						if(window.LossesBelow != ""){
							d.LossesBelow = window.LossesBelow;
						}
						if(window.incomeAb != ""){
							d.incomeAb = window.incomeAb;
						}
						if(window.incomeBel != ""){
							d.incomeBel = window.incomeBel;
						}
						if(window.policyHe != ""){
							d.policyHe = window.policyHe;
						}
						if(window.newlyEstabDays != ""){
							d.newlyEstabDays = window.newlyEstabDays;
						}

					}
	            },
	            "columns": dtColumns
	        });

            $("#clientListForm select").on('change', function() {
                $('#filterClientsOwned').hide();
                $('#filterInsuranceClass').hide();
                var v = $(this).val();

                if (v === 'insuranceClass' || v === 'clientsOwned' || v === 'clientsStatus' || v === 'clientsType' || v === 'criminalPractice' || v ==='SIAOnPolicyAbove' || v ==='SIAOnPolicyBelow' || v ==='totalLossRatioAbove'|| v ==='totalLossRatioBelow'|| v ==='totalRevenueAbove'|| v ==='totalRevenueBelow'|| v ==='totalLossesAbove'|| v ==='totalLossesBelow'|| v ==='incomeAbove'|| v ==='incomeBelow'|| v ==='policyHeld'|| v ==='newlyEstablished') {
                    if (v === 'clientsOwned') {
                        $('#filterClientsOwned').show();
                    } else if (v === 'insuranceClass') {
                        $('#filterInsuranceClass').show();
                    } else if (v === 'clientsStatus') {
                        $('#clientsStatus').show();
                    } else if (v === 'clientsType') {
                        $('#clientsType').show();
                    } else if(v === 'criminalPractice') {
                        $('#criminalPractice').show();
                    } else if(v === 'SIAOnPolicyAbove') {
                        $('#SIAOnPolicyAbove').show();
                    } else if(v === 'SIAOnPolicyBelow') {
                        $('#SIAOnPolicyBelow').show();
                    } else if(v === 'totalLossRatioAbove') {
                        $('#totalLossRatioAbove').show();
                    } else if(v === 'totalLossRatioBelow') {
                        $('#totalLossRatioBelow').show();
                    } else if(v === 'totalRevenueAbove') {
                        $('#totalRevenueAbove').show();
                    } else if(v === 'totalRevenueBelow') {
                        $('#totalRevenueBelow').show();
                    } else if(v === 'totalLossesAbove') {
                        $('#totalLossesAbove').show();
                    } else if(v === 'totalLossesBelow') {
                        $('#totalLossesBelow').show();
                    } else if(v === 'incomeAbove') {
                        $('#incomeAbove').show();
                    } else if(v === 'incomeBelow') {
                        $('#incomeBelow').show();
                    }  else if(v === 'policyHeld') {
                        $('#policyHeld').show();
                    } else if(v === 'newlyEstablished') {
                        $('#newlyEstablished').show();
                    }
                } else {
                    tblSelec.state.clear();
                    $(this).parents('form').submit();
                }
            });

            $("#filterClientsOwned select").on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#filterInsuranceClass select').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#clientsStatus select').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#clientsType select').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#criminalPractice select').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#SIAOnPolicyAbove input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#SIAOnPolicyBelow input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#totalLossRatioAbove input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#totalLossRatioBelow input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#totalRevenueAbove input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#totalRevenueBelow input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#totalLossesAbove input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#totalLossesBelow input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#incomeAbove input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#incomeBelow input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#policyHeld select').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });

            $('#newlyEstablished input').on('change', function() {
                tblSelec.state.clear();
                $(this).parents('form').submit();
            });
  		}
  	},
    clientConsole 	: {
        init 	: function () {
            $(".chosen").chosen();
        }
    },
  	clientEdit 	: {
  		init 	: function () {
  			$("#i1-5").chosen();
		    $("#i1-7").chosen();
		    $("#i1-9").chosen();

		    var showHides = $('.show-hide-pass');
		    showHides.click(function(){
		        var $this = $(this),
		            action = $this.hasClass('show') ? 'show' : 'hide',
		            inputType = action === 'show' ? 'text' : 'password';

		        $('#pw').get(0).type = inputType;
		        showHides.toggle();
		    });


		    var filds = $('#fields');
		    var frm = $('div.linkedFormAddNew').clone();

		    var v;
            //console.log(".linkedFormWrapper");
            //230718 - added .linkedFormWrapper to the following selectors as it was capturing clicks
            // and submission of ALL jsonforms within this clientEdit page, so when trying to edit a
            // client it was submitting to /clients/edit/ajax_sectors
		    $('.linkedFormWrapper .jsonForm').on('click', 'input[type=submit]', function() {
		        v = $(this).attr('name');
		    });

		    $('.linkedFormWrapper .jsonForm').on('submit', function(e) {
		        e.preventDefault();

		        var ths = $(this);
		        ths.addClass("disabled").find("input[type=submit]").prop("disabled", true).prop("readonly", true);

		        var data = $(this).serialize() + "&method=" + v;

		        $.post(window.contextPath + "/clients/edit/ajax_sectors", data)
		            .done(function(data) {
		                if (data.success) {
		                    //alert("success");

		                    if (data.method === "add") {
		                        var newFrm = frm.clone();
		                        filds.append(newFrm);
		                        ths.removeClass('linkedFormAddNew').addClass('linkedForm');
		                        ths.find('input[type=submit]').val('Save').attr('name', 'saveSectorReference').after($('<input id="submit" type="submit" name="deleteSectorReference" value="-" style="pointer-events: auto;">'));
		                        ths.find('input[name=selfId]').val(data.id);

		                    } else if (data.method === "delete") {
		                        ths.remove();

		                        noty({
		                            text: 'Sector saved successfully.',
		                            theme: 'relax',
		                            layout: 'bottomRight',
		                            timeout: 2000,
		                            animation: {
		                                open: {height: 'toggle'},
		                                close: {height: 'toggle'},
		                                speed: 500
		                            }
		                        });
		                    } else if (data.method === "save") {
		                        noty({
		                            text: 'Sector saved successfully.',
		                            theme: 'relax',
		                            layout: 'bottomRight',
		                            timeout: 2000,
		                            animation: {
		                                open: {height: 'toggle'},
		                                close: {height: 'toggle'},
		                                speed: 500
		                            }
		                        });
		                    }
		                } else {
		                    alert("Sector ID could not be added, because it already associated to " + data.companyName);
		                }
		            })
		            .fail(function(data) {
		                alert("Sector ID could not be added, because it already associated to another client.");
		            })
		            .always(function() {
		                ths.removeClass("disabled").find("input[type=submit]").prop("disabled", false).prop("readonly", false);
		            });
		        return false;
		    });

  		}
  	},
  	clientEditMain 	: {
  		init 	: function() {
  			var tblSelec = $("#tbl");

	        tblSelec.dataTable( {
	            "processing": true,
	            "serverSide": true,
	            "ordering": false,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "ajax": {
	                "url": window.contextPath + "/clients/" + window.clientID + "/login/datatable.json",
	                "data": function ( d ) {
	                    d.clientID = window.clientID;
	                }
	            },
	            "columns": [
	                { data: "firstName" },
	                { data: "lastName" },
	                { data: "email" },
	                {
	                    data: "edit",
	                    render: function ( data, type, row ) {
	                        if ( type === 'display' ) {
	                            return '<a href="'+window.contextPath+'/clients/' + window.clientID + '/login/edit/' + row.id + '" class="icon-edit">Edit</a>';
	                        }
	                        return row;
	                    },
	                },
	                { data: "delete",
	                    render: function ( data, type, row ) {
	                        if ( type === 'display' ) {
	                            return '<a href="'+window.contextPath+'/clients/' + window.clientID + '/login/delete/' + row.id + '">Delete</a>';
	                        }
	                        return row;
	                    },
	                }
	            ]
	        });
  		}
  	},
  	clientEditLogin 	: {
  		init 	: function () {
  			var pm = $('#permissions');

            $('#accessLevel').change(function() {
                if ($(this).val() === 'partial') {
                    pm.css('display', 'block');
                } else {
                    pm.css('display', 'none');
                }
            });
            //on load, trigger initial change
            $('#accessLevel').trigger("change");

            //if password is emptied set accesslevel to none
            $("#pw").on("change",function(){
                if($(this).val().length<1){
                    $("#accessLevel").val("none").trigger("chosen:updated").trigger("change");
                }
            })

  		}
  	},
  	clientCreate 	: {
  		init 	: function() {
  	        var showHides = $('.show-hide-pass');
	        showHides.click(function(){
	            var $this = $(this),
	                    action = $this.hasClass('show') ? 'show' : 'hide',
	                    inputType = action === 'show' ? 'text' : 'password';

	            $('#pw').get(0).type = inputType;
	            showHides.toggle();
	        });
  		}
  	},
  	clientCreateLogin 	: {
  		init 	: function () {
	        var showHides = $('.show-hide-pass');

	        showHides.click(function(){
	            var $this = $(this),
	                    action = $this.hasClass('show') ? 'show' : 'hide',
	                    inputType = action === 'show' ? 'text' : 'password';

	            $('#pw').get(0).type = inputType;
	            showHides.toggle();
	        });


	        var pm = $('#permissions');

	        pm.css('display', 'none');

	        $('#accessLevel').change(function() {
	            if ($(this).val() === 'partial') {
	                pm.css('display', 'block');
	            } else {
	                pm.css('display', 'none');
	            }
	        });

            //if password is emptied set accesslevel to none
            $("#pw").on("change",function(){
                if($(this).val().length<1){
                    $("#accessLevel").val("none").trigger("chosen:updated").trigger("change");
                }
            })

  		}
  	},
  	activitylogList 	: {
  		init 	: function() {
  			$("#tbl").dataTable( {
	            "processing": true,
	            "serverSide": true,
	            "ordering": false,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "ajax": {
	                "url": window.contextPath + "/activity-logs/datatable.json"
	            },
	            "columns": [
	                { data: "id" },
	                { data: "action" },
	                { data: "description" },
	            ]
	        });
  		}
  	},
  	pfDashboard 	: {
  		init 	: function () {
	        $(".notification .delete").on('click', function(e) {
	            e.preventDefault();

	            var parentDiv = $(this).parent();
	            var notificationID = $(parentDiv).attr("data-notification");
	            $.post(window.contextPath + '/notifications/read', { "notificationID": notificationID}).done(function(data) {
	                parentDiv.fadeOut(function() {
	                    parentDiv.remove();
	                    minusNotificationCounter();
	                });
	            });
	        });

		    function minusNotificationCounter() {
		        /*$(".alert-num").each(function() {
		            var count = parseInt($(this).text());
		            $(this).text(count - 1);
		        })*/

		        $('body').load(window.contextPath + '/');
		    }
		    $('a.menu').on('click', function(e) {
		      $('body').toggleClass("openNav");
		      e.preventDefault();
		    });

		   	//Header notifications
		    $('#headband .alert-num').on('click', function(e) {
		    	console.log("FIRED");
		      $('#headband').toggleClass("headband-notify");
		      e.preventDefault();
		    });

		    $(function() {
			    $('#assignedRoles').on('change', function() {
			        var vl = $(this).val();
			        var target = $('#assignedRoles_' + vl);
			        $('.person').hide();
			        target.show();
			    });
			    $('.headerChosen').chosen({disable_search_threshold: 100, width: '100%'});
			});
  		}
  	},
  	documentsPage 	: {
  		init 	: function () {
  			table = $("#tbl").DataTable( {
	            createdRow: function( row, data, dataIndex ) {
	                // Set the data-status attribute, and add a class
	                $( row ).find('td:eq(0)')
	                        .attr('data-label', 'File Name');
	                $( row ).find('td:eq(1)')
	                    .attr('data-label', 'Document Type');
	                $( row ).find('td:eq(2)')
	                        .attr('data-label', 'Policy Type');
	                $( row ).find('td:eq(3)')
	                        .attr('data-label', 'Year');
	                $( row ).find('td:eq(4)')
	                        .attr('data-label', 'Actions');
	            },
	            "order": [[ 1, "desc" ]],
	            rowId: 'id',
	            "processing": true,
	            "serverSide": true,
	            "ordering": true,
	            "stateSave": true,
	            "lengthMenu": [ 15, 25, 50, 75, 100 ],
	            "ajax": {
	                "url": window.contextPath+ "/documents/datatable.json",
	                "data": function ( d ) {
		                if(window.insuranceTypeFilter != ""){
		                    d.insuranceTypeFilter = window.insuranceTypeFilter;
		                }
		                if(window.documentTypeFilter != ""){
		                    d.documentTypeFilter = window.documentTypeFilter;
		                }
		                if(window.yearFilter != ""){
		                    d.yearFilter = window.yearFilter;
		                }
	                }, orderable: false
	            },
	            "columns": [
	                { data: "fileName", orderable: true },
	                { data: "documentType", orderable: true },
	                { data: "insuranceType", orderable: true },
	                {
	                    data: "year",
	                    render: function ( data, type, row ) {
	                        if ( type === 'display' ) {
	                            return '<td>'+row.year+'</td>';
	                        }
	                        return row;
	                    }, orderable: false
	                },
	                {
	                    data: "delete",
	                    render: function ( data, type, row ) {
	                        if ( type === 'display' ) {
	                            return '<td>' +
	                                    '<a class="downloads" title="download" href="'+window.contextPath+'/documents/download?file=' + row.id + '"><span class="dlText">Download</span><svg class="svg-icon"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#download"></use></svg></a>' +
	                                    '</td>';
	                        }
	                        return row;
	                    }, orderable: false
	                },
	            ]
	        });
  		}
  	},
  	dashboardContact 	: {
  		init 	: function () {
  			$('#staff').chosen({disable_search_threshold: 10, width: '100%'});

  		}
  	},
  	accountDetails 	: {
  		init 	: function () {
  			var showHides = $('.show-hide-pass');
        	showHides.click(function(){
	            var $this = $(this),
	                action = $this.hasClass('show') ? 'show' : 'hide',
	                inputType = action === 'show' ? 'text' : 'password';

	            $('#pw').get(0).type = inputType;
	            showHides.toggle();
	        });
  		}
  	},
  	pfCheckout 	: {
  		init 	: function () {
  			$(document).trigger("stunnForm-utils-init");

  			// this is a BASIC call to moovalidate. Consult jsonForm for how to do it properly if any issues are encountered if we extend the functionality on the checkout (such as for saving progress)
  			$("form").on("keyup change valchange", ":input:not([type=hidden])", function(e){
                $(this).mooValidate(e.type);
            });

         	$("form").find(":input").not(".linkedFormAddNew :input").not("[type=hidden]").each(function(){
                $(this).mooValidate("onload");
            });

  		}
  	},
  	checkoutPaymentcomplete	: 	{
  		init 	: function () {
  			$("#complete-print").on("click", function(e) {
                window.print();
            });
  		}
  	},
  	sanctionedCountries 	: {
  		init 	: function () {
  			$('#sanctionedCountriesList').chosen({disable_search_threshold: 10});

  		}
  	},
	quotesList 		: {
  		init 	: function() {

            var table;

            var dtColumns = [];

            //Quote ID
			dtColumns.push( { data:"quotationID"} );

            //Quote Ref
            if(window.siteName == "mobility"){
			    dtColumns.push( { data:"quoteReference", defaultContent:"-"});
            }
			//Client Name
            if (!window.ajax) {
                dtColumns.push({data: "clientCompanyName"});
            }

			// Insurance
            if (!window.ajax) {
                dtColumns.push({data: "insurance"});
            }

			// Total Premium
			dtColumns.push({data:"premium"});

            if(window.siteName != "lsos") {
                dtColumns.push({data:"recommended"});
                dtColumns.push({data:"shortlisted"});
            }
            if(window.siteName == "lsos") {
                dtColumns.push({data:"isVisibleToClient"});
                dtColumns.push({data:"isSentToClient"});
                dtColumns.push({data:"rsaRef", defaultContent:"-"});
                dtColumns.push({data:"policyID"});
            }

			// Quote Detail Link
            dtColumns.push({
                data: "detail",
                render: function (data, type, row) {
                    if (type === 'display') {
                        var url = window.contextPath + '/quotes/' + row.quotationID;
                        return '<a href="' + url + '">View</a>';
                    }
                    return row;
                }, orderable: false
            });

            // data: "edit",
            //     render: function ( data, type, row ) {
            //     if ( type === 'display' ) {
            //         return '<a href="'+window.contextPath+'/clients/' + window.clientID + '/login/edit/' + row.id + '" class="icon-edit">Edit</a>';
            //     }
            //     return row;
            // },

            $(document).ready(function() {
                table = $("#datatable-quotes").DataTable( {
					rowId: 'quotationID',
                    "processing": true,
                    "ordering": true,
                    "serverSide": true,
                    "stateSave": true,
                    "lengthMenu": [ 15, 25, 50, 75, 100 ],
                    "ajax": {
                        "url": window.contextPath + "/quotes/datatable.json",
                        "data": function ( d ) {
							if(window.insuranceTypeSelec != ""){
								d.insuranceType = window.insuranceTypeSelec;
							}
							if(window.clientSelec != ""){
								d.client = window.clientSelec;
							}
							if(window.policyYearSelec != ""){
								d.policyYear = window.policyYearSelec;
							}
                        },
                        orderable: false
                    },
                    "columns": dtColumns
                });

                $("#filter select").on('change', function() {
                    table.state.clear();
                    $(this).parents('form').submit();
                });
            });
		}
	},
	transactionList 	: {
  		init : function() {

            var table;

            $(document).ready(function() {
                table = $("#datatable-transactions").DataTable( {
                    "order": [[ 0, "desc" ]],
                    rowId: 'transactionID',
                    "processing": true,
                    "serverSide": true,
                    "ordering": true,
                    "stateSave": true,
                    "lengthMenu": [ 15, 25, 50, 75, 100 ],
                    "ajax": {
                        "url": window.contextPath + "/transactions/datatable.json",
                        "data": function ( d ) {
                            if(window.insuranceTypeSelec != ""){
                                d.insuranceType = window.insuranceTypeSelec;
                            }
                            if(window.clientSelec != ""){
                                d.client = window.clientSelec;
                            }
                            if(window.policyYearSelec != ""){
                                d.policyYear = window.policyYearSelec;
                            }
                            if(window.statusSelec != ""){
                                d.status = window.statusSelec;
                            }
                        }
                    },
                    "columns": [
                        {data: "transactionID"},
                        {data: "timestampInSeconds"},
                        {data: "orderSummary"},
                        {data: "status"},
                        {data: "totalPriceInPence"},
                        {
                            data: "detail",
                            // The `data` parameter refers to the data for the cell (defined by the
                            // `data` option, which defaults to the column being worked with, in
                            // this case `data: 0`.
                            "render": function ( data, type, row ) {
                                //var url = window.contextPath + '/transactions/' + row[0];
                                var url = window.contextPath + '/transactions/' + row.transactionID;
                                return '<a href="' + url + '">View</a>';
                            },
                            "targets": 5,
                            orderable: false
                        }
                        //{ "visible": false,  "targets": [ 3 ] }
                    ]
                });
                $("#filter select").on('change', function() {
                    table.state.clear();
                    $(this).parents('form').submit();
                });
            });
		}
	},
    clientConsoleInsurances: {
        /* this isnt a problem on lsos, dunno what's changed here */
        init: function () {
            $(".client-console-insurances .title-select:first:visible").chosen({disable_search_threshold: 10});
        }
    },
    clientConsoleSummary: {
        init: function () {
            $(".client-console-summary .title-select:first:visible").chosen({disable_search_threshold: 10});
        }
    },
	partnerHistory 	:	{
  		init : function() {

            var table;

            $(document).ready(function() {
                table = $("#datatable-partnerHistory").DataTable( {
                    "order": [[ 0, "desc" ]],
                    rowId: 'partnerLogId',
                    "processing": true,
                    "serverSide": true,
                    "ordering": true,
                    "stateSave": true,
                    "lengthMenu": [ 15, 25, 50, 75, 100 ],
                    "ajax": {
                        "url": window.contextPath + "/partnerHistory/datatable.json",
                        "data": function ( d ) {
							if(window.clientSelec != ""){
								d.client = window.clientSelec;
							}
							if(window.partnerType != ""){
								d.partnerType = window.partnerType;
							}
							if(window.stillAtCompany != ""){
								d.stillAtCompany = window.stillAtCompany;
							}
                        },
                        orderable: false
                    },
                    "columns": [
                        {data: "companyName"},
                        {data: "contactName"},
                        {data: "start_date"},
                        {data: "end_date"}
                    ]
                });
                $("#filter select").on('change', function () {
                    table.state.clear();
                    $(this).parents('form').submit();
                });
            });
		}
	},
	reportRowPerClient: {
		init : function() {

			$(function(){
				$("#statusFilter select").on('change', function() {
					$(this).parents('form').submit();
				});
			});


			var table;
			var CompleteData;
			function returnColumns(data){
				console.log(data);
				CompleteData = data;
				var row = document.getElementById("tblColumns");
				$.each(data.columns.data, function( k, v ) {
					var x = document.createElement("TH");
					x.innerHTML = v;
          row.appendChild(x);
        })
				var dataSet = data.dataForColumns.data;
				$('#tbl').DataTable( {
					"processing": false,
					"stateSave": true,
					"pagingType": "full_numbers",
					"lengthMenu": [ 15, 25, 50, 75, 100 ],
					data: dataSet
				});

				//"drawCallback": secondCellRowSpans,
				function secondCellRowSpans() {

					var table = document.getElementById("tbl"),
						j = table.rows.length-1,
						slastcell = table.rows[j].cells[1],
						slastValue = slastcell.innerText,
						spann =1;
					while(j--)
					{
						var thissCell = table.rows[j].cells[1],
							thissValue = thissCell.innerText;

						if(thissCell.className != "done"){
							if(thissValue == slastValue){
								table.rows[j+1].deleteCell(1); // the first cell of the previous row (remember we're iterating in reverse)
								spann++;
							} else {
								slastcell.rowSpan = spann;
								spann = 1;
							}
							slastValue = thissValue;
							slastcell = thissCell;

						}

					}
					firstCellRowSpans();


				};


				function firstCellRowSpans(){
					var table = document.getElementById("tbl"), // you'll prob need .getElementById() here
						i = table.rows.length-1,
						lastCell = table.rows[i].cells[0], // the first cell of last row
						lastValue = lastCell.innerText,
						span = 1;
					while(i--){//reverse iterate, last to first

						var thisCell = table.rows[i].cells[0],
							thisValue = thisCell.innerText;

						if(thisCell.className != "done"){
							if(thisValue == lastValue){
								table.rows[i+1].deleteCell(0); // the first cell of the previous row (remember we're iterating in reverse)
								span++;
							} else {
								lastCell.rowSpan = span;
								span = 1;
								lastCell.className = "done";
							}
							lastValue = thisValue;
							lastCell = thisCell;
						}
					}
					// AddingColorToTheColumn();
				};

				function AddingColorToTheColumn() {
					console.log("inside adding color");
					var k=0;
					var table = document.getElementById("tbl");
					$('table:first tr').each(function() {
						var lasttd=  $(this).find(':last-child')
						var secondlast = $(this).find(':nth-last-child(2)')
						if(lasttd.html() >= 1) {
							console.log('RED');
							secondlast.css("background-color", "RED");
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','red');
							lasttd.html( '&#x2715');
							//lasttd.remove();	;
						} else if((lasttd.html() == 0 || lasttd.html() < 0) && lasttd.html() != "AMBER" ) {
							console.log('GREEN');
							secondlast.css("background-color", "GREEN");
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','green');
							lasttd.html( '&#x2714');
							//lasttd.remove();
						} else if(lasttd.html().includes("AMBER")) {
							console.log('AMBER');
							secondlast.css("background-color", "ORANGE");
							var st = lasttd.html().replace('AMBER-','');
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','ORANGE');
							lasttd.html(st.concat(' Days to Go'));

							//lasttd.html( '&#x2715');
							//lasttd.remove();
						}
						//lasttd.remove();
					});
					ran = true;
				};
			}

			var filterParams = function(){
        var d = {};
        if(window.pfListFilter['status'] != ""){
          d.status = window.pfListFilter['status'];
        }
        if(window.pfListFilter['policyType'] != ""){
          d.policyType = window.pfListFilter['policyType'];
        }
        d.reportType='row_for_each_client'

        return d;
			};

			console.log(window.pfListFilter['status']);
			$.ajax({
				url: window.contextPath + '/reports/row_per_client.json',
				data: filterParams(),
				success: function (data) {
					console.log(data);
					returnColumns(data);
				}
			});
		}
	},
	reportYearOnYear: {
		init : function() {
			$(function(){
				$("#statusFilter select").on('change', function() {
					$(this).parents('form').submit();
				});
			});


			var table;
			var CompleteData;
			function returnColumns(data){
				console.log(data);
				CompleteData = data;
				var row = document.getElementById("tblColumns");
				$.each(data.columns.data, function( k, v ) {
          var x = document.createElement("TH");
          x.innerHTML = v;
          row.appendChild(x);
				})
				var dataSet = data.dataForColumns.data;
				$('#tbl').DataTable( {
					"processing": false,
					"stateSave": false,
					"pagingType": "full_numbers",
					"lengthMenu": [ 15, 25, 50, 75, 100 ],
					"drawCallback": secondCellRowSpans,
					data: dataSet
				});

				//"drawCallback": secondCellRowSpans,
				function secondCellRowSpans() {
					if(1>0) {
						console.log("safhsdl");
						//window.doneSpans = true;
						var table = document.getElementById("tbl"),
							j = table.rows.length - 1,
							slastcell = table.rows[j].cells[1],
							slastValue = slastcell.innerText,
							spann = 1;

						while (j--) {
							var thisRow = table.rows[j],
								thissCell = thisRow.cells[1],
								thissValue = thissCell.innerText;

							if (thissCell.className != "xxxdone" && (thisRow.className != "done" || j==0)) {
								if (thissValue == slastValue) {
									table.rows[j + 1].deleteCell(1); // the first cell of the previous row (remember we're iterating in reverse)
									spann++;
								} else {
									slastcell.rowSpan = spann;
									spann = 1;
								}
								slastValue = thissValue;
								slastcell = thissCell;
							}
						}
						firstCellRowSpans();
					}
				};


				function firstCellRowSpans(){
					var table = document.getElementById("tbl"), // you'll prob need .getElementById() here
						i = table.rows.length-1,
						lastCell = table.rows[i].cells[0], // the first cell of last row
						lastValue = lastCell.innerText,
						span = 1;
					while(i--){//reverse iterate, last to first

						var thisRow = table.rows[i],
							thisCell = thisRow.cells[0],
							thisValue = thisCell.innerText;

						if(thisCell.className != "done"){
							if(thisValue == lastValue){
								table.rows[i+1].deleteCell(0); // the first cell of the previous row (remember we're iterating in reverse)
								span++;
							} else {
								lastCell.rowSpan = span;
								span = 1;
								lastCell.className = "done";
							}
							lastValue = thisValue;
							lastCell = thisCell;
						}

						thisRow.className = "done";
					}
					// AddingColorToTheColumn();
				};

				function AddingColorToTheColumn() {
					console.log("inside adding color");
					var k=0;
					var table = document.getElementById("tbl");
					$('table:first tr').each(function() {
						var lasttd=  $(this).find(':last-child')
						var secondlast = $(this).find(':nth-last-child(2)')
						if(lasttd.html() >= 1) {
							console.log('RED');
							secondlast.css("background-color", "RED");
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','red');
							lasttd.html( '&#x2715');
							//lasttd.remove();	;
						} else if((lasttd.html() == 0 || lasttd.html() < 0) && lasttd.html() != "AMBER" ) {
							console.log('GREEN');
							secondlast.css("background-color", "GREEN");
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','green');
							lasttd.html( '&#x2714');
							//lasttd.remove();
						} else if(lasttd.html().includes("AMBER")) {
							console.log('AMBER');
							secondlast.css("background-color", "ORANGE");
							var st = lasttd.html().replace('AMBER-','');
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','ORANGE');
							lasttd.html(st.concat(' Days to Go'));
						}
					});
					ran = true;
				};
			}

      var filterParams = function(){
        var d = {};
        if(window.pfListFilter['status'] != ""){
          d.status = window.pfListFilter['status'];
        }
        if(window.pfListFilter['policyType'] != ""){
          d.policyType = window.pfListFilter['policyType'];
        }
        if(window.pfListFilter['formYearStart'] != ""){
          d.startYear = window.pfListFilter['formYearStart'];
        }
        if(window.pfListFilter['formYearEnd'] != ""){
          d.endYear = window.pfListFilter['formYearEnd'];
        }
        d.reportType='year_on_year_comparision'

        return d;
      };

			$.ajax({
				url: window.contextPath + '/reports/year_on_year.json',
				data: filterParams(),
				success: function (data) {
					console.log(data);
					returnColumns(data);
				}
			});
		}
	},
	reportMilestone : {
		init : function () {

			$(function(){
				$("#statusFilter select").on('change', function() {
					$(this).parents('form').submit();
				});
			});


			var table;
			var CompleteData;
			function returnColumns(data){
				console.log(data);
				CompleteData = data;
				var row = document.getElementById("tblColumns");
				$.each(data.columns.data, function( k, v ) {
          var x = document.createElement("TH");
          x.innerHTML = v;
          row.appendChild(x);
				})
				var dataSet = data.dataForColumns.data;
				$('#tbl').DataTable( {
					"processing": false,
					"stateSave": true,
					"pagingType": "full_numbers",
					"lengthMenu": [ 15, 25, 50, 75, 100 ],
					"drawCallback": secondCellRowSpans,
					data: dataSet
				});

				//"drawCallback": secondCellRowSpans,
				function secondCellRowSpans() {

					var table = document.getElementById("tbl"),
						j = table.rows.length-1,
						slastcell = table.rows[j].cells[1],
						slastValue = slastcell.innerText,
						spann =1;
					while(j--)
					{
						var thissCell = table.rows[j].cells[1],
							thissValue = thissCell.innerText;

						if(thissCell.className != "done"){
							if(thissValue == slastValue){
								table.rows[j+1].deleteCell(1); // the first cell of the previous row (remember we're iterating in reverse)
								spann++;
							} else {
								slastcell.rowSpan = spann;
								spann = 1;
							}
							slastValue = thissValue;
							slastcell = thissCell;
						}
					}
					firstCellRowSpans();
				};


				function firstCellRowSpans(){
					var table = document.getElementById("tbl"), // you'll prob need .getElementById() here
						i = table.rows.length-1,
						lastCell = table.rows[i].cells[0], // the first cell of last row
						lastValue = lastCell.innerText,
						span = 1;
					while(i--){//reverse iterate, last to first

						var thisCell = table.rows[i].cells[0],
							thisValue = thisCell.innerText;

						if(thisCell.className != "done"){
							if(thisValue == lastValue){
								table.rows[i+1].deleteCell(0); // the first cell of the previous row (remember we're iterating in reverse)
								span++;
							} else {
								lastCell.rowSpan = span;
								span = 1;
								lastCell.className = "done";
							}
							lastValue = thisValue;
							lastCell = thisCell;
						}
					}
					AddingColorToTheColumn();
				};

				function AddingColorToTheColumn() {
					console.log("inside adding color");
					var k=0;
					var table = document.getElementById("tbl");
					$('table:first tr').each(function() {
						var lasttd=  $(this).find(':last-child');
						var secondlast = $(this).find(':nth-last-child(2)');
						if(lasttd.html() >= 1) {
							console.log('RED'+lasttd.html());
							secondlast.css("background-color", "RED");
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','red');
							lasttd.html( '&#x2715');
							//lasttd.remove();	;
						} else if((lasttd.html() == 0 || lasttd.html() < 0) && lasttd.html() != "AMBER" ) {
							console.log('GREEN'+lasttd.html());
							secondlast.css("background-color", "GREEN");
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','green');
							lasttd.html( '&#x2714');
							//lasttd.remove();
						} else if(lasttd.html().includes("AMBER")) {
							console.log('AMBER'+lasttd.html());
							secondlast.css("background-color", "ORANGE");
							var st = lasttd.html().replace('AMBER-','');
							lasttd.css('text-align','center');
							lasttd.css('font-size','150%');
							lasttd.css('font-weight','bold');
							lasttd.css('color','ORANGE');
							lasttd.html(st.concat(' Days to Go'));

							//lasttd.html( '&#x2715');
							//lasttd.remove();
						}
						//lasttd.remove();
					});
					ran = true;
				};

			}

      var filterParams = function(){
        var d = {};
        if(window.pfListFilter['status'] != ""){
          d.status = window.pfListFilter['status'];
        }
        if(window.pfListFilter['policyType'] != ""){
          d.policyType = window.pfListFilter['policyType'];
        }
        d.reportType='milestone_report'

        return d;
      };

			$.ajax({
				url: window.contextPath + '/reports/milestone_report.json',
				data: filterParams(),
				success: function (data) {
					console.log(data);
					returnColumns(data);
				}
			});
		}
	},
	reportPartnerReportAll: {
		init : function() {

			$(function(){
				$("#filter select").on('change', function() {
					$(this).parents('form').submit();
				});
			});


			var table;
			var CompleteData;
			function returnColumns(data){
				console.log(data);
				CompleteData = data;
				var row = document.getElementById("tblColumns");
				$.each(data.columns.data, function( k, v ) {
          var x = document.createElement("TH");
          x.innerHTML = v;
          row.appendChild(x);
				})
				var dataSet = data.dataForColumns.data;
				$('#tbl').DataTable( {
					"processing": false,
					"stateSave": false,
					"pagingType": "full_numbers",
					"lengthMenu": [10, 25, 50, 100],
					"drawCallback": AddingColorToTheColumn,
					buttons: [
						'csvHtml5'
					],
					data: dataSet
				});

				function AddingColorToTheColumn() {
					console.log("inside adding color");
					var k=0;
					var table = document.getElementById("tbl");
					$('table:first tr').each(function() {
						var lasttd=  $(this).find(':last-child');

						if((lasttd.html() == "red") || (lasttd.html() == "100%")) {
							console.log('red');
							lasttd.html("100%");
							lasttd.css("background-color", "RED");
							//lasttd.remove();	;
						} else if((lasttd.html() == "pink") || (lasttd.html() == "80%")) {
							lasttd.html("80%");
							console.log('pink');
							lasttd.css("background-color", "PINK");
							//lasttd.remove();	;
						} else if((lasttd.html() == "orange") || (lasttd.html() == "60%")) {
							lasttd.html("60%");
							console.log('orange');
							lasttd.css("background-color", "ORANGE");
							//lasttd.remove();	;
						} else if((lasttd.html() == "yellow") || (lasttd.html() == "40%")) {
							lasttd.html("40%");
							console.log('yellow');
							lasttd.css("background-color", "YELLOW");
							//lasttd.remove();	;
						}
						//lasttd.remove();
					});
				};
			}

      var filterParams = function(){
        var d = {};

        d.reportType='partner_report_all'

        return d;
      };

			$.ajax({
				url: window.contextPath + '/reports/partner_report_all.json',
				data: filterParams(),
				success: function (data) {
					console.log(data);
					returnColumns(data);
				}
			});
		}
	},
    clientLoginList 	:	{
        init : function() {

            var table;

            $(document).ready(function() {

                table = $("#datatable-clientLoginList").DataTable( {
                    "order": [[ 0, "desc" ]],
                    rowId: 'partnerLogId',
                    "processing": true,
                    "serverSide": true,
                    "ordering": true,
                    "stateSave": true,
                    "lengthMenu": [ 15, 25, 50, 75, 100 ],
                    "ajax": {
                        "url": window.contextPath + "/clientContactsList/datatables.json",
                        "data": function ( d ) {
                            if(window.clLoginListFilter["clientId"] != ""){
                                d.clientId = window.clLoginListFilter["clientId"];
                            }
                            if(window.clLoginListFilter["dashboardUser"] != ""){
                                d.dashboardUser = window.clLoginListFilter["dashboardUser"];
                            }
                        	if(window.clLoginListFilter["dashboardRole"] != ""){
                                d.dashboardRole = window.clLoginListFilter["dashboardRole"];
                            }
                            if(window.clLoginListFilter["sjaRole"] != ""){
                                d.sjaRole = window.clLoginListFilter["sjaRole"];
                            }
                            if(window.clLoginListFilter["partner"] != ""){
                                d.partner = window.clLoginListFilter["partner"];
                            }
                            if(window.clLoginListFilter["bankrupt"] != "") {
                            	d.bankrupt = window.clLoginListFilter["bankrupt"];
							}
                        },
                        orderable: false
                     },
                    "columns": [
                        {data: "id"},
                        {data: "firstName"},
                        {data: "lastName"},
                        {data: "jobTitle"},
                        {data: "companyName"},
                        {
                            data: "edit",
                            // The `data` parameter refers to the data for the cell (defined by the
                            // `data` option, which defaults to the column being worked with, in
                            // this case `data: 0`.
                            render: function (data, type, row) {
                                // if (type === 'display') {
                                return '<a href="' + window.contextPath + '/clients/' + row.clientID + '/login/edit/' + row.id + '" class="icon-edit">Edit</a>';
                                // }
                                //todo - this should be /contact/edit?
                                // return row;
                            },
                            "targets": 6,
                            orderable: false
                        }
                        //{ "visible": false,  "targets": [ 3 ] }
                    ]
                });//dt end

				//on change of filter
                $("#clientLoginFilter select").on('change', function() {
                    table.state.clear();
                    $(this).parents('form').submit();
                });

            });//domready end
        }
    }
};
/**
 * A set of utility functions for stunnForm (taken from original jsonForm 2.0.0)
 */
/**
 * List of included functions:
 * isFilled
 * isAnswered
 * floatVal
 * intVal
 * percentVal
 * cashVal
 * mooValidate
 * setValueAttr
 */

$(document).on("stunnForm-utils-init",function(){
    $.fn.extend({
        isFilled: function(clause){
            //determin if the field has been filled FOR TOGGLING (in the sense that it matches the value required to show dependant questions)
            if(this!==undefined){
                var $this = $(this),
                    type = $this.attr("type");
                if(clause && clause!==""){
                    //whats the clause?
                    var regExp = /\(.+\)/;
                    var matches = regExp.exec(clause);
                    /* NEW special case for conveyancy
                    If the required-dependant-if clase specifies 'grouptot' we should sum the values of all field with the same data-value-group
                        "data-value-group": "conveyancingY2",
                        "data-required-dependant-if": "(grouptot>=20 || ((smallFirm) && grouptot>=5)) #p7"
                    */
                    var grouptot = 0;
                    if(clause.indexOf("grouptot")>-1){
                        var datavaluegroup = $this.attr("data-value-group");
                        //console.log("datavaluegroup="+datavaluegroup);
                        $('.jsonForm :input[data-value-group='+datavaluegroup+']').each(function(){grouptot+=(parseFloat($(this).val()) || 0);});
                    }
                    //end special conveyancy bit
                    if(matches && matches.length>0){
                        clause = matches[0];
                        
                        //100316 when the clause is for the value of a field which has a 'presentational' value, we need to use its data-float-value rather than the input value
                        if(clause.indexOf("this.value")>-1 && $this.attr("data-float-value")){
                            clause = clause.replace(/this.value/g,'$this[0].getAttribute(\"data-float-value\")');
                        } else {//100316
                            clause = clause.replace(/this/g,'$this[0]');
                            clause = clause.replace(/grouptot/g, grouptot);//NEW group total
                        }//100316
                        
                    }
                    //console.warn(clause+"="+eval(clause));
                    //I know, eval is evil, but there's no alternative (other than having a separate named method for each possible clause)...
                    //console.log(clause+"="+eval(clause));
                    return eval(clause);
                }
                //console.log(type);
                switch (type){
                  case "percent":
                  case "cash":
                  case "number":
                    //console.log($this.floatVal());
                    return ($this.floatVal()>0);
                  case "radio":
                    var val = $("input[name='"+$this.attr("name")+"']:checked" ).val() || "";
                    //console.log("$(\"input[name='"+$this.attr("name")+"']:checked\" ).val()="+val);
                    return (val=="1" || val.toLowerCase()=="yes");
                  case "checkbox":
                    return ($this.is(":checked"));
                  default:
                    //text
                    return ($this.val()!=="");
                }
            }
        },
        isAnswered: function(clause){
            //determin if the field (or one radio collection) has a value
            if(this!==undefined){
                var $this = $(this),
                    type = $this.attr("type");
                switch (type){
                  case "radio":
                    //if its a radio, one of them must be checked
                    var val = $("input[name='"+$this.attr("name")+"']:checked" ).val() || "";
                    return (val!=="");
                    /* falls through */
                  case "checkbox":
                    //if its a checkbox we have no way of knowing if its been answered unless its required
                    if($this.prop("required")){
                        return ($this.is(":checked"));
                    } else {
                        return false;
                    }
                    /* falls through */
                  case "number":
                    return ($this.val()!=="");
                    /* falls through */
                  case "submit":
                    return false;
                    /* falls through */
                  default:
                    //text
                    if($this.attr("data-value")){
                        //console.log("datavalue="+$this.attr("data-value"));
                        return ($this.val()!=="")|| ($this.attr("data-value")!=="");
                    } else {
                        return ($this.val()!=="");
                    }
                    //return ($this.val()!=="" || $this.data("value")!=="");
                }
            }
        },
        floatVal: function(newval) {
            //getter - returns value of first matched element (parsed as a float)
            //var val = newval || this[0].value,
            if(this[0]===undefined){return "";}
            var val = (newval!==null && newval!==undefined)?newval:this[0].value;
            if($(this).attr("data-float-value")){val = $(this).attr("data-float-value");}
            var n = (""+val).replace(/[^0-9\.\-]+/g,''),
                //f = parseFloat(n),
                f = parseFloat(n).toFixed(12),
                floatValue = (isNaN(f)?0:f);
            floatValue = new BigNumber(floatValue).toNumber();//use bignumber
            if(newval!==null && newval!==undefined){
                return this.each(function(){
                    this.value = ""+floatValue;
                });
            }
            return floatValue;
        },
        intVal: function(newval) {
            if(this[0]===undefined){return 0;}
            var val = (newval!==null && newval!==undefined)?newval:this[0].value,
                n = (""+val).replace(/[^0-9\.\-]+/g,''),
                f = parseInt(n),
                intValue = (isNaN(f)?0:f);
            if(newval!==null && newval!==undefined){
                return this.each(function(){
                    this.value = ""+intValue;
                });
            }
            return intValue;
        },
        OLDpercentVal: function(newval){
            //getter - returns value of first matched element (formatted as a percentage)
            //setter - set value of matched element (formatted as a percentage)
            var val = (newval!==null && newval!==undefined)?newval:this[0].value,
                n = (""+val).replace(/[^0-9\.\-]+/g,''),
                f = parseFloat(n);
                    f = (isNaN(f)?0:f);
                var i = parseInt(f),
                    tdp = ""+f.toFixed(2),
                    odp = ""+f.toFixed(1);
                i = (parseFloat(i)<parseFloat(tdp))?tdp:(parseFloat(i)<parseFloat(odp))?odp:i;//to zero or 2dp
                var percentValue = i+"%";
            //allow empty fields
            if(val.length===0){
                return this.each(function(){
                    this.value = "";
                });
            }
            if(newval!==null && newval!==undefined){
                return this.each(function(){
                    this.value = percentValue;
                    /*if($(this).attr("placeholder")===percentValue){
                        this.value = "";
                    }*/
                });
            }
            return (val.length===0)?"":percentValue;
        },
        percentVal: function(newval,forceRealValToFloatVal){
            //console.log("percentVal('"+newval+"',"+forceRealValToFloatVal+")");
            //070316
            /* Notes to me
            Store as floats (with exponetial notation if required)
            calc using real (float) value
            present as float.toString() rounded to 2 dp as required
            on hover, tooltip shows float.toString()

            var floatVal = "4.01234567891234590320e+1"; //value of REAL (hidden) input, stored in db
            var tooltipVal = f.toString(); = "40.12345678912346"; //shown on hover
            var presentationalVal = parseFloat(tooltipVal).toFixed(2); = "40.12"; //rendered in ADDITIONAL input box
            
            FYI parseFloat on a string with a trailing percentage will work. eg parseFloat("10.00%") = 10;

            */
            //getter - returns value of first matched element (formatted as a percentage)
            //setter - set value of matched element (formatted as a percentage)
            var val = (newval!==null && newval!==undefined)?newval:this[0].value,
                n = (""+val).replace(/[^0-9\.\-]+/g,''),
                f = parseFloat(n);
            f = (isNaN(f)?0:f);
            //console.warn(val);
            var pval = (parseFloat(f.toFixed(2)) == parseFloat(f.toFixed(1)))?((parseFloat(f.toFixed(1)) == parseFloat(f.toFixed(0)))?f.toFixed(0):f.toFixed(1)):f.toFixed(2);
                pval = (isNaN(pval)?0:pval);
            var percentValue = pval+"%";
            //allow empty fields
            if(val.length===0){
                return this.each(function(){
                    //This may now be the PRESENTATIONAL field
                    this.value = "";
                    this.setAttribute("value", "");
                    this.setAttribute("data-tooltip-value", "0.00");
                    this.setAttribute("data-float-value", "0%");
                    if($(this).attr("data-aofb-as-percent") || $(this).next("input[type=hidden]").length>0){
                        //Find the ACTUAL REAL field
                        $(this).next("input[type=hidden]")
                            .val("")//set its value
                            .attr("title","0.0");//set its title
                        $(this).parent().find(".tt").html("0%");//and the tooltip span.tt html
                    }
                });
            }
            if(newval!==null && newval!==undefined){
                return this.each(function(){
                    //This may now be the PRESENTATIONAL field
                    this.value = (forceRealValToFloatVal)?f:percentValue;
                    this.setAttribute("value", (forceRealValToFloatVal)?f:percentValue);
                    this.setAttribute("data-tooltip-value", f.toFixed(12).toString());
                    this.setAttribute("data-float-value", f.toFixed(12)+"%");
                    if($(this).attr("data-aofb-as-percent") || $(this).next("input[type=hidden]").length>0){
                        //Find the ACTUAL REAL field
                        $(this).next("input[type=hidden]")
                            .val(f+"%")//mirror its value
                            .attr("title",parseFloat(f.toFixed(12)).toString());//set its title
                        $(this).parent().find(".tt").html(f.toString()+"%");//and the tooltip span.tt html
                    }
                });
            }
            return (val.length===0)?"":percentValue;
        },
        cashVal: function(newval){
            //getter - returns value of first matched element (formatted as cash)
            //setter - set value of all matched element (formatted as cash)
            var val = (newval!==null && newval!==undefined)?newval:this[0].value,
                decPlaces = 2,
                decSeparator = ".",
                thouSeparator = ",",
                currencySymbol = "£",
                n = (""+val).replace(/[^0-9\.\-]+/g,''),
                sign = n < 0 ? "-" : "",
                i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "",
                j = (j = i.length) > 3 ? j % 3 : 0,
                cashValue = sign + currencySymbol + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
                //console.warn(val,n);
            //allow empty fields
            if(val.length===0){
                return this.each(function(){
                    this.value = "";
                });
            }
            if(newval!==null && newval!==undefined){
                return this.each(function(){
                    this.value = cashValue;
                    /*if($(this).attr("placeholder")===cashValue){
                        this.value = "";
                    }*/
                });
            }

            return (val.length===0)?"":cashValue;
        },
        //mooValidate : function(){
        mooValidate : function(eventTypeTestString){
            var valid = true,
                $this = $(this),
                //value = $this.val(),
                value = $this.attr("data-float-value") || $this.val(),
                type = $this.attr("type"),
                id = $this.attr("id") || "",
                pattern = $this.attr("pattern"),
                required = $this.prop("required") || ($this.attr("required")=="required") || ($this.attr("area-required")=="true"),
                message = (pattern)?"Invalid format.":"This field is required.",
                matched = false,
                answered = $this.isAnswered(),
                unanswered = !answered;

            // console.log("mooValidate("+eventTypeTestString+") : "+id+"");

            //linkedFormCSVs
            if($this.is("[type$='.jsp']")){
                //do we have to validate the length?
                var matchCSVlength = $this.data("csv-length");
                if(matchCSVlength){
                    if(matchCSVlength.indexOf("#") === 0){
                        //begins with a hash (the id of another field) so we need to get the value of that field
                        matchCSVlength = $(matchCSVlength).intVal();
                    }
                    //console.warn($this.attr("name")+" matchCSVlength="+matchCSVlength);
                    if(matchCSVlength>0){
                        //^([0-9]*(,|$)){5}$
                        var newPattern = "^([0-9]*(,|$)){"+matchCSVlength+"}$";
                        $this.attr("pattern",newPattern);
                        pattern = $this.attr("pattern");
                    }
                }
            }



            //use the title for the message (and remove the title so we don't see two tooltips)
            if($this.attr("title")){
                var t = $this.attr("title");
                $this.attr("data-title",t);
                $this.attr("title","");
                message = t;
            } else if($this.attr("data-title")){
                message = $this.attr("data-title");
            }


            //NEW 190717
            //the worksplits sum total shouldn't be validated against the data-float-value but the normal presentational value
            //if($this.is("[type=percent]") && ($this.attr("data-table-formula")=="sumColumn")){
            //if($this.is("[type=percent]") && (($this.attr("data-table-formula")=="sumColumn") || ($this.attr("data-aofb-as-percent")=="#i6-1-36-4 | #lastCompletedYear"))){
            if($this.is("[type=percent]")){
                value = $this.val();
                //console.warn("............."+value+".............");
            }



            if(pattern){
                //pattern
                var ptn = new RegExp(pattern);
                matched = ptn.test(value);
                //if($(this).attr("id")=="i6-1-36-3"){//100316 test
                //    console.warn(pattern,value);
                //}
            } else {
                //if there's no pattern any value is a match
                matched = true;
            }

            if(required && unanswered){
                valid=false;
            }

            if(answered && matched){
                valid=true;
            }

            //Added vanilla .matches:invalid beacuse input type emails we'er being marked as valid by the app but shown as invalid by the browser
            if(valid===true && $this[0].matches(":invalid")){
                valid=false;
            }

            /*$this
                .attr("data-valid",valid)
                .attr("data-matched",matched)
                .attr("data-message",message)
                .attr("data-answered",answered);*/

            $this.attr("data-answered",answered);

            if($this.is("[type=radio]")){
                //ALL radios of the same name must have their data-answered set the same
                //are any radios in this collection answered?
                //$(":input[name='"+n+"']").each(function(){});
                var n = $this.attr("name");
                $(":input[name='"+n+"']").attr("data-answered",answered).data("answered",answered);
                $(":input[name='"+n+"']").each(function(){
                    if(answered){
                        valid=true;
                        matched=true;
                        $(this).removeClass("error")
                                .addClass("valid")
                                .closest(".jf-row").children(".mvalidation").remove();
                    }
                });
            }

            if($this.is("[type=checkbox]") && $this.is("[data-apply-method=onoffswitch]")){
                //toggle switches have been answered by default
                answered = true;
                $this.attr("data-answered",answered);
            }

            /* moved to setValueAttr
            if($this.is("[type=radio]") || $this.is("[type=checkbox]")){
                //
            } else {
                $this.attr("value",value);
            }
            */

            $this
                .data("valid",valid)
                .data("matched",matched)
                .data("message",message)
                .data("answered",answered);


            //console.log($this.attr("name") + " "+pattern+" against "+value+" = "+matched+ " required="+required+" valid="+valid+" matched="+matched+" answered="+answered+"");

            /*if(required){//only care about required fields being awswerd
                $this.attr("data-answered",answered)
            }*/

            $this.removeClass("valid error");

            var $fieldset = $this.parents("fieldset");
                if($fieldset.length>0){
                    $fieldset.attr("data-hasanswers",answered);
                }

            //only tag up the field if it is required or has a pattern to match
            if(required || pattern){

                

                var $parent = $this.parent("td");
                if($parent.length<=0){
                    $parent = $this.closest(".collection").attr("data-isanswered",answered);
                }
                if($parent.length<=0){
                    $parent = $this.closest(".jf-row");
                }

                if(required && valid && matched){
                    $this.addClass("valid");
                    $parent.children(".mvalidation").remove();
                } else {
                //if(required && (!valid || !matched)){
                    $this.addClass("error");
                    /*if($this.parent(".jf-row").children(".mvalidation").length <=0 ){
                        var $lastInCollection = ($this.parent("label").length>0)?$this.parent("label"):$this.parent(".jf-row");
                        $lastInCollection.append($("<label class='mvalidation error' for='"+id+"'>"+message+"</label>"));
                    }
                    if($this.next(".mvalidation").length <=0 ){
                        $this.after($("<label class='mvalidation error' for='"+id+"'>"+message+"</label>"));
                    }*/
                    var w = ((message.length * 0.52)+1)+"em";
                    if($this.is("[type$='.jsp']")){
                        $this.after($("<label class='mvalidation error' for='"+id+"' style='width:"+w+"'>"+message+"</label>"));
                    } else if($parent.children(".mvalidation").length <=0 ){
                        $parent.append($("<label class='mvalidation error' for='"+id+"' style='width:"+w+"'>"+message+"</label>"));
                    }

                }

            }


            return valid;
        },
        setValueAttr: function(){
            //101215 set the attr value on change. For checkboxes and radio's set the checked=checked
            if(this!==undefined){
                var $this = $(this),
                    type = $this.attr("type");
                switch (type){
                  case "radio":
                    //if its a radio, one of them must be checked but all the others shouldnt be
                    if($this.is(":checked")){
                        $("input[name='"+$this.attr("name")+"']").not(":checked").removeAttr("checked");
                        $this.attr("checked","checked");
                    } else {
                        $this.removeAttr("checked");
                    }
                    break;
                  case "checkbox":
                    //if its a checkbox set checked=checked accordingly
                    if($this.is(":checked")){
                        $this.attr("checked","checked");
                        //return ($this.val());
                    } else {
                        $this.removeAttr("checked");
                        //return false;
                    }
                    break;
                  case "submit":
                    return false;
                    //break;//unreachable
                  default:
                    //everything else, set the value attr equal to the val()
                    $this.attr("value",$this.val());
                }
            }
            return this;//?
        }
    });
});
/*!
 *  Stunnplate script.js
 *  Version: 1.3.2
 *
 */
 /* 
 *  
 *  Notes:
 *  Main JS file to include entire library followed by custom scripts.
 *
 * 
 */

/*-------------------------------------------------------*/
/*- SITE SPECIFIC SCRIPT                                -*/
/*-------------------------------------------------------*/

//This is no longer the place for page specific stuff. make sure you use _pagespecific.js for that! (040117 MG)

/************************* Misc ****************************/

/* ---------------------------------------------------------------------- */
/*  Chosen                                                                *?
/* ---------------------------------------------------------------------- */

function setupChosen() {
    if (jQuery().chosen) {
        var chosenOptions = {
            disable_search_threshold: 10
        };

        var width = $('.chosen-style').data('chosen-width');

        if (width != null) {
            chosenOptions.width = width;
        } else {
            chosenOptions.width = '100%';
        }

        $(".chosen-style").filter(function(){
            return !$(this).data("chosen")
        }).chosen(chosenOptions);
    }
}


/* ---------------------------------------------------------------------- */
/*  Event Calendar                                                        *?
/* ---------------------------------------------------------------------- */
//this is causing and error and not used so commenting it
// $(window).load(function() {
//     $("#eventCalendar_side").eventCalendar({
//       // link to events json
//       eventsjson: '/events/eventsJson.jsp',
//       eventsLimit : 1,
//       txt_NextEvents: "Next event:",
//       jsonDateFormat: 'human',
//       showDescription: false
//     });
// });

/****************** AJAX filrers ***************************/

$(window).on("load", function (e) {
    if($('.news-detail-page').length > 0){
        var newsID = $('body').attr("data-newsID"),
            siteName = $('body').attr("data-siteName");

        console.log("/news/hitsUpdater.jsp?newsID=" + newsID);
        // console.log(newsID);
        setTimeout(function(){ 
            $.ajax({
              url: "/news/hitsUpdater.jsp?newsID=" + newsID,
              context: document.body
            });
        }, 3000);
    }
});


/********************************************************************/



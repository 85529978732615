// Resources filters
function filterSet(filterLi,newFilterText,filterLink){
    $('#main').load(filterLink + " #ajax-results", function(responseText, statusText, xhr){
        $(".filterBar-wrap").removeClass("open");
    });
}

function filterReset(){
    var label = $(this).data("label");

    if($(this).hasClass("filterAll")){ //Check if this is the "view all button" if so don't do much
        $(this).removeClass("filterOn");
    } else{ // Other filters need a little more work
        //Get rid of class but also reset label
        $(this).find(".filterBar-text").text(label);
        $(".filterBy-indicator").text(label);
        $(this).removeClass("filterOn");
    }
}
// Menu functions

function menuHover(){
    $('li.first-level-submenu-parent').unbind('mouseenter mouseleave');
    if ($(window).width() > 991) {
        $('li.first-level-submenu-parent').hover(function () {
            clearTimeout($.data(this,'timer'));
            $('ul.first-level-submenu',this).stop(true,true).slideDown(200);
            $( this ).css('background-color', '#f8f9f7');
            $( "a.submenu-sibling", this ).css('color', '#0086bf');
        }, function () {
            $.data(this,'timer', setTimeout($.proxy(function() {
                $('ul.first-level-submenu',this).stop(true,true).slideUp(200);
                $( this ).removeAttr( 'style' );
                $( "a.submenu-sibling", this ).removeAttr( 'style' );
            }, this), 100));
        });
    }
}

function closeMobileMenu() {
    // if ($(window).width() > 991) {
        $("body").removeClass("is-offcanvas-open");
        $(".submenu-opened").removeClass("submenu-opened");
        $(".submenu").removeAttr( 'style' );
        $(".hidden-box").removeClass("hidden-box-opened").removeAttr( 'style' );
        $(".main-nav-search, .main-nav-login, .hidden-box-sibling").removeClass("hidden-box-opened");
    // }
}

function toggleMobileMenu(){
    // $("#main-nav-wrap, #page-container").toggleClass("menu-opened");
    $("body").toggleClass("is-offcanvas-open");
    $(".submenu-opened").removeClass("submenu-opened");
    $(".submenu").removeAttr( 'style' );
    $(".hidden-box").removeClass("hidden-box-opened").removeAttr( 'style' );
    // $(".main-nav-search, .main-nav-login, .hidden-box-sibling").removeClass("hidden-box-opened");
}

function openSubmenu(e) {
    if ($(window).width() < 992) {
        e.preventDefault();
        $(this).toggleClass("submenu-opened");
        $(this).siblings(".submenu").toggleClass("submenu-opened").slideToggle();
    }
}